import { compact, orderBy } from "lodash";
import { useState } from "react";

import { Drawer, drawerIcon, DrawerRow } from "@/components/drawer";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import { useSavor } from "@/hooks/use-savor";
import { SavedItemFragment, useCreateListMutation, useUpdateSavedItemMutation } from "../../generated/graphql";

interface Props {
  savedItem?: SavedItemFragment;
  showLists?: boolean;
  handleClose: () => void;
}

export const Lists: React.FC<Props> = ({ savedItem, showLists, handleClose }) => {
  const { myLists } = useSavor();
  const [updateItem] = useUpdateSavedItemMutation();
  const [loadingId, setLoadingId] = useState<string>();
  const [addList, setAddList] = useState(false);
  const [listTitle, setListTitle] = useState<string>();
  const [createList, { loading: creatingList }] = useCreateListMutation();

  if (!savedItem) {
    return null;
  }

  const isMember = (listId: string) => savedItem.lists.some((l) => l.id === listId);

  const toggleListMembership = (listId: string) => {
    setLoadingId(listId);
    updateItem({
      variables: {
        input: { itemId: savedItem.id, ...(isMember(listId) ? { removeFromList: listId } : { addToList: listId }) },
      },
      onCompleted: () => setLoadingId(undefined),
    });
  };

  const { lists } = savedItem;
  const myOrderedLists = orderBy(myLists, (l) => l.label, "asc");

  return (
    <Drawer
      handleClose={() => {
        setAddList(false);
        setListTitle("");
        handleClose();
      }}
      title="Add to Lists"
      visible={showLists}
    >
      <div className="border-background/5 border-b">
        {addList ? (
          <div className="flex items-center px-6">
            <Icon icon="add" className={drawerIcon} />
            {creatingList ? (
              <Loading className="w-full h-14 text-lg px-6" />
            ) : (
              <Input
                placeholder="List Title"
                autoFocus
                value={listTitle}
                onChange={(e) => setListTitle(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (listTitle) {
                      createList({
                        variables: { input: { label: listTitle } },
                        updateQueries: {
                          MyLists: (prev, { mutationResult }) => {
                            return {
                              myLists: compact([...prev.myLists, mutationResult.data?.createList]),
                            };
                          },
                        },
                        onCompleted: (res) => {
                          setListTitle("");
                          setAddList(false);
                          toggleListMembership(res.createList.id);
                        },
                      });
                    }
                    e.preventDefault();
                  }
                }}
                className="bg-inherit border-0 flex-auto h-14 px-6 py-3 placeholder:text-background/50 text-inherit text-lg focus-visible:ring-0 focus-visible:ring-offset-0"
              />
            )}
            {listTitle && listTitle.length > 0 && <Icon icon="keyboard_return" className={drawerIcon} />}
          </div>
        ) : (
          <button
            className="flex flex-auto gap-6 h-14 items-center px-6 text-left text-lg"
            onClick={() => setAddList(true)}
          >
            <Icon icon="add" className={drawerIcon} />
            <span className="flex-auto font-medium">Create New List</span>
          </button>
        )}
      </div>
      <div className="flex-auto overflow-y-auto py-6 space-y-6">
        {lists[0] && (
          <div>
            <h5 className="opacity-50 px-6 py-2">Added To</h5>
            {lists.map((list) => (
              <DrawerRow
                key={list.id}
                id={list.id}
                label={list.label}
                checked={true}
                loading={loadingId === list.id}
                handleClick={() => toggleListMembership(list.id)}
              />
            ))}
          </div>
        )}
        <div>
          <h5 className="opacity-50 px-6 py-2">All Lists</h5>
          {myOrderedLists.map((list) => {
            return (
              <DrawerRow
                key={list.id}
                id={list.id}
                label={list.label}
                checked={isMember(list.id)}
                loading={loadingId === list.id}
                handleClick={() => toggleListMembership(list.id)}
              />
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};
