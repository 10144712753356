import { partition } from "lodash";

import { cn } from "@/utils";
import { EntityFragment } from "../../generated/graphql";
import { EntitySection } from "./entity";

interface EntityLinksProps {
  entity: EntityFragment;
}

export const EntityLinks: React.FC<EntityLinksProps> = ({ entity }) => {
  if (!entity.links?.[0]) {
    return null;
  }

  const [affiliate, nonAffiliate] = partition(entity.links, (l) => l.affiliate);

  return (
    <EntitySection header="Links">
      <div className="flex flex-col gap-1">
        {[...affiliate, ...nonAffiliate].map(({ url, label }, key) => (
          <a
            key={key}
            className={cn("bg-border/25 block px-4 py-2 rounded text-center text-primary text-sm")}
            href={url}
            target="_blank"
          >
            <span className="truncate">{label}</span>
          </a>
        ))}
      </div>
      {affiliate.length > 0 && (
        <footer className="italic mt-4 opacity-50 text-center text-2xs">
          We may earn commission from purchases made via sponsored links.
        </footer>
      )}
    </EntitySection>
  );
};
