import { debounce } from "lodash";
import { useCallback, useMemo } from "react";

export const useDebounce = (mutation: (val?: any) => void, deps: any[], ms?: number) => {
  const onChange = useCallback(mutation, deps);

  // memoize the debounce call with useMemo
  const debouncedRequest = useMemo(() => {
    return debounce(onChange, ms ?? 1000);
  }, [onChange]);

  return {
    debouncedRequest,
  };
};
