import { useIsLoggedIn } from "@/auth/user";
import { Thumbnail } from "@/components/thumbnail";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useAppNavigate } from "@/hooks/use-app-navigate";
import { formatDistanceToNow } from "@/utils/format-distance-to-now";
import { compact, groupBy } from "lodash";
import Markdown from "react-markdown";
import { ActivityMessageFragment, KnownEventName, useGetActivityQuery } from "../../generated/graphql";

interface DisplayableMessage {
  timestamp: string | Date;
  text: string;
  primaryResourceUrl: string;
  comment?: string | null;
  imageUrl?: string | null;
  private: boolean;
}

export const ActivityFeed: React.FC = () => {
  const loggedIn = useIsLoggedIn();
  const { data: { getActivity: activityFeed } = {}, loading } = useGetActivityQuery();
  const navigate = useAppNavigate();

  if (!activityFeed) {
    return null;
  }

  const messages = getDisplayableMessages(filterMessages(activityFeed));

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <ul className="flex flex-col gap-6">
          {!loggedIn && <h2 className="w-full text-center">Happening Now on Savor</h2>}
          {messages.map(({ timestamp, primaryResourceUrl, imageUrl, text, comment, private: isPrivate }) => {
            // TODO: Should be a shared hook
            const relativeTime = formatDistanceToNow(new Date(timestamp), { addSuffix: false });

            return (
              <li key={timestamp.toString()} className="border-t first:border-none flex gap-6 items-center pt-6">
                <Thumbnail
                  src={imageUrl}
                  className={"h-full aspect-square min-w-12"}
                  onClick={() => navigate(primaryResourceUrl)}
                />
                <div className="flex flex-col gap-1">
                  <Markdown
                    components={{
                      a: (props) => (
                        <span className="cursor-pointer text-primary" onClick={() => navigate(props.href!)}>
                          {props.children}
                        </span>
                      ),
                      em: (props) => <span className="italic text-muted-foreground text-sm">{props.children}</span>,
                    }}
                  >
                    {`${text}${
                      !comment
                        ? ""
                        : `

${comment
  .split("\n")
  .map((line) => (line.length > 0 ? `*${line.trim()}*` : ""))
  .join("\n")}`
                    }`}
                  </Markdown>
                  <h6 className="text-muted-foreground">{relativeTime}</h6>
                </div>
                {isPrivate && (
                  <div className="flex flex-grow justify-end">
                    <Icon className="text-xl text-gray-400 h-full ml-4" icon="visibility_off" />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

const filterMessages = (messages: ActivityMessageFragment[]): ActivityMessageFragment[] => {
  const getEntityKey = (m: ActivityMessageFragment) => `${m.username}-${m.entityId}`;
  const getListKey = (m: ActivityMessageFragment) => `${m.username}-${m.listId}`;

  // Group all events by username + entityId
  const byUserAndEntity = groupBy(messages, getEntityKey);

  // Drop all SavedItem events if they reoccur in any other kind of event
  const withoutDupeSaveMessages = messages.filter(
    (m) => m.eventName !== KnownEventName.ItemSaved || byUserAndEntity[getEntityKey(m)].length < 2
  );

  // Merge all CommentAdded events into AddedToList events just with a comment
  const withCommentsMerged = withoutDupeSaveMessages
    .map((m) => {
      if (m.eventName !== KnownEventName.AddedToList) {
        return m;
      }
      const commentByUser = byUserAndEntity[getEntityKey(m)].find((m) => m.eventName === KnownEventName.CommentAdded);
      return commentByUser ? { ...m, comment: commentByUser.comment } : m;
    })
    .filter((m) => {
      if (m.eventName !== KnownEventName.CommentAdded) {
        return true;
      }
      const hasList = byUserAndEntity[getEntityKey(m)].find((m) => m.eventName === KnownEventName.AddedToList);
      return !hasList;
    });

  // Group messages by user + listId
  // Group all events by username + entityId
  const byUserAndList = groupBy(messages, getListKey);

  // Drop any ListCreated events if they have any other kind of activity
  const withoutDupeListMessages = withCommentsMerged.filter(
    (m) => m.eventName !== KnownEventName.ListCreated || byUserAndList[getListKey(m)].length < 2
  );

  return withoutDupeListMessages;
};

const getDisplayableMessages = (messages: ActivityMessageFragment[]): DisplayableMessage[] => {
  return compact(
    messages.map((m) => {
      switch (m.eventName) {
        case KnownEventName.ItemSaved:
          if (!m.entityId || !m.entityTitle) {
            return null;
          }
          return {
            ...m,
            primaryResourceUrl: `/entity/${m.entityId}?fromUsername=${m.username}`,
            text: `[@${m.username}](/profile/${m.username}) saved [${m.entityTitle}](/entity/${
              m.entityId
            }?fromUsername=${m.username})${
              m.sourceUsername ? ` via [@${m.sourceUsername}](/profile/${m.sourceUsername})` : ""
            }`,
          };
        case KnownEventName.ListCreated:
          if (!m.listId || !m.listLabel) {
            return null;
          }
          return {
            ...m,
            primaryResourceUrl: `/lists/${m.listId}`,
            text: `[@${m.username}](/profile/${m.username}) created a List: [${m.listLabel}](/lists/${m.listId})`,
          };
        case KnownEventName.AddedToList:
          if (!m.listId || !m.listLabel || !m.entityId || !m.entityTitle) {
            return null;
          }
          return {
            ...m,
            primaryResourceUrl: `/entity/${m.entityId}?fromUsername=${m.username}`,
            text: `[@${m.username}](/profile/${m.username}) added [${m.entityTitle}](/entity/${m.entityId}?fromUsername=${m.username}) to [${m.listLabel}](/lists/${m.listId})`,
          };
        case KnownEventName.CommentAdded:
          if (!m.comment || !m.entityId || !m.entityTitle) {
            return null;
          }
          return {
            ...m,
            primaryResourceUrl: `/entity/${m.entityId}?fromUsername=${m.username}`,
            text: `[@${m.username}](/profile/${m.username}) commented on [${m.entityTitle}](/entity/${m.entityId})`,
          };
        default:
          return null;
      }
    })
  );
};
