import { orderBy, uniqBy } from "lodash";

import { Items, ItemsContainer } from "@/components/items";
import { Loading } from "@/components/ui/loading";
import { useProfileUser } from "@/hooks/use-profile-user";

export const Library: React.FC = () => {
  const { items, loading } = useProfileUser();

  const orderedItems = orderBy(
    uniqBy(items, (si) => si.entity?.id),
    (si) => si.updatedAt,
    "desc"
  );

  return loading ? (
    <Loading />
  ) : (
    <ItemsContainer>
      <Items items={orderedItems} />
    </ItemsContainer>
  );
};
