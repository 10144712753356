import { HTMLAttributes } from "react";

import { cn } from "@/utils";
import { ImageHeader } from "./image-header";

export const Footer: React.FC<HTMLAttributes<HTMLElement> & { header: string }> = ({
  header,
  children,
  className,
  ...props
}) => (
  <footer
    className={cn("flex flex-auto flex-col justify-center items-center px-6 pt-24 text-center", className)}
    {...props}
  >
    <ImageHeader>{header}</ImageHeader>
    {children}
    <div className="h-48 w-full" />
  </footer>
);
