import { EntityFragment } from "../../generated/graphql";
import { EntitySection } from "./entity";

interface Props {
  entity: EntityFragment;
  fromUsername?: string;
  recommendersNote?: string;
}

export const EntityDetails: React.FC<Props> = ({ entity, fromUsername, recommendersNote }) => {
  const RecommendersNote = () => {
    if (!recommendersNote) {
      return null;
    }

    return (
      <EntitySection header="recommendations">
        <aside className="bg-border/10 flex flex-col gap-4 p-4 rounded-lg">
          <p>{recommendersNote}</p>
          <footer className="h-4 text-2xs text-primary">@{fromUsername}</footer>
        </aside>
      </EntitySection>
    );
  };

  return (
    <>
      <RecommendersNote />
      {entity.description && (
        <EntitySection header="Description">
          <p className="leading-normal text-justify text-lg">{entity.description}</p>
        </EntitySection>
      )}
    </>
  );
};
