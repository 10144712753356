import { LoginCard } from "./login-card";

export const PublicIntro: React.FC = () => {
  return (
    <div className="flex flex-col m-auto justify-center items-center max-w-2xl gap-y-4 p-4">
      <h2>Welcome to Savor</h2>
      {/* <p>Your Collaborative Wishlist and Recommendations App</p> */}
      <p>Our goal is to ensure you can always find a high quality, trusted recommendation when you need it.</p>

      <p className="w-full mb-0">
        And we have great news! Your network is already full of rich, untapped potential that just needs a good way to
        access and uncover it. We aim to enable this by helping you
      </p>
      <table className="-mt-2 ml-4">
        <tbody>
          <tr>
            <td className="align-top">1.</td>
            <td>
              Get recommendations from your your friends, their friends, and new users with common interests who you may
              not know.
            </td>
          </tr>
          <tr>
            <td className="w-8 align-top">2.</td>
            <td>
              Capture the recommendations you receive in day-to-day life so you can return to them when you need them
            </td>
          </tr>
          <tr>
            <td className="align-top">3.</td>
            <td>
              Be rewarded for sharing the great things you try or are interested in so both you and your network can
              benefit from your experiences
            </td>
          </tr>
        </tbody>
      </table>
      <p className="w-full">We're incredibly early on in our journey, but hope you'll join us for the ride </p>
      <LoginCard />
    </div>
  );
};
