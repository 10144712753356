import * as Tabs from "@radix-ui/react-tabs";
import { compact } from "lodash";
import { useState } from "react";
import { useParams } from "react-router";

import { NavButton, NavContainer } from "@/components/nav";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { useAppNavigate } from "@/hooks/use-app-navigate";
import { useMyAccount } from "@/hooks/use-my-account";
import { useSavor } from "@/hooks/use-savor";
import { subscribeToPush } from "@/utils/subscribe-to-push";
import { EntityFragment, useCreateShareMutation, useSaveItemMutation } from "../../generated/graphql";
import { Lists } from "./lists";
import { Share } from "./share";
import { Sources } from "./sources";
import { usePollForRecipe } from "./use-poll-for-recipe";

interface Props {
  entity: EntityFragment;
  fromUsername?: string;
  setTabValue: React.Dispatch<string>;
}

export const EntityFooter: React.FC<Props> = ({ entity, fromUsername, setTabValue }) => {
  const { shareId: shareParam } = useParams();
  const myAccount = useMyAccount();
  const navigate = useAppNavigate();
  const { savedItems, itemsLoading, setSearchBarText, setSearchVisible, targetListId, setTargetListId } = useSavor();
  const [showLists, setShowLists] = useState(false);
  const [showSources, setShowSources] = useState(false);

  const savedItem = savedItems.find((si) => si.entity?.id === entity.id);
  const isUnsaved = myAccount && !itemsLoading && !savedItem;

  const [createShare] = useCreateShareMutation();
  const [shareId, setShareId] = useState<string>();
  const [saveItem, { loading: saving }] = useSaveItemMutation();

  const { failed } = usePollForRecipe(entity);

  return (
    <>
      <NavContainer id="EntityFooter">
        {itemsLoading ? null : isUnsaved ? (
          <NavButton
            component={saving ? <Spinner /> : undefined}
            icon="favorite"
            title="Save"
            onClick={() => {
              saveItem({
                variables: { input: { entityId: entity.id, fromShareId: shareParam, fromUsername } },
                updateQueries: {
                  MySavedItems: (prev, { mutationResult }) => {
                    return {
                      savedItemsForUser: compact([...prev.savedItemsForUser, mutationResult.data?.saveItem]),
                    };
                  },
                },
                onCompleted: () => {
                  setSearchBarText("");
                  setSearchVisible(false);
                  // By default this gets unset when we clear the search, but we want to maintain it in this case
                  setTargetListId(targetListId);
                },
              });
              // TODO: I think we should move this to activity feed
              subscribeToPush(myAccount.id);
            }}
          />
        ) : myAccount ? (
          <>
            <Tabs.List className="flex gap-1 items-center">
              <NavButton icon="forum" title="Activity" onClick={() => setTabValue("activity")} />
              <NavButton icon="info" title="Details" onClick={() => setTabValue("details")} />
              {entity.recipeUrl && !failed && entity.recipeSummary?.trim() !== "null" && (
                <NavButton
                  className={entity.recipeSummary ? "" : "disabled"}
                  component={entity.recipeSummary ? undefined : <Spinner />}
                  icon="liquor"
                  title="Recipe"
                  onClick={() => setTabValue("recipe")}
                />
              )}
            </Tabs.List>
            <NavButton icon="format_list_bulleted" title="Lists" onClick={() => setShowLists(true)} />
            <NavButton icon="reviews" title="Sources" onClick={() => setShowSources(true)} />
            <NavButton
              icon="send"
              title="Share"
              onClick={() =>
                !shareId
                  ? createShare({
                      variables: { input: { entityId: entity.id } },
                      onCompleted: (res) => setShareId(res.createShare.id),
                    })
                  : undefined
              }
            />
          </>
        ) : (
          <Button
            variant="ghost"
            className="text-md"
            onClick={() => {
              return navigate(
                `/login?entityId=${entity.id}${shareParam ? `&shareId=${shareParam}` : `&fromUsername=${fromUsername}`}`
              );
            }}
          >
            Sign In to Add to Library
          </Button>
        )}
      </NavContainer>
      <Share entity={entity} shareId={shareId} handleClose={() => setShareId(undefined)} />
      <Lists savedItem={savedItem} showLists={showLists} handleClose={() => setShowLists(false)} />
      <Sources savedItem={savedItem} showSources={showSources} handleClose={() => setShowSources(false)} />
    </>
  );
};
