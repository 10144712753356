import ReactDOM from "react-dom/client";
import "regenerator-runtime/runtime";
import { App } from "./site/app";

if (process.env.NODE_ENV !== "development") {
  // FullStory.init({ orgId: "o-19XRGJ-na1" });
}

export const getServiceWorkerRegistration = async () =>
  "serviceWorker" in navigator
    ? (await navigator.serviceWorker.getRegistration()) ??
      (await navigator.serviceWorker.register("/service-worker.js"))
    : undefined;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = document.getElementById("root")!;
ReactDOM.createRoot(root).render(<App />);
