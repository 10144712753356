import { MouseEventHandler } from "react";

import { cn, shortTitle } from "@/utils";
import { ImageWithFallback } from "./image-with-fallback";

interface Props {
  alt?: string;
  className?: string;
  src: string | undefined | null;
  reaction?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const Thumbnail: React.FC<Props> = ({ alt, className, src, reaction, onClick }) => {
  return (
    <div
      className={cn(
        "group aspect-square bg-white cursor-pointer overflow-hidden relative rounded shadow text-[1rem]",
        className
      )}
      onClick={onClick}
    >
      <ImageWithFallback
        className="absolute bg-white brightness-[0.8] contrast-[1.2] h-full invert-[0.2] object-cover object-top w-full"
        src={src ?? ""}
      />
      <div
        className="absolute font-[1000] h-full p-3 leading-[0.95em] overflow-hidden text-white tracking-wide uppercase w-full z-[3]"
        style={{ textShadow: "0 0 0.5em rgba(0 0 0 / 0.1), 0.25em 0.25em 1.5em rgba(0 0 0 / 0.1)" }}
      >
        {shortTitle(alt)}
      </div>
      {reaction && reaction !== "👀" && (
        <div className="absolute bottom-0 leading-tight p-2 text-lg w-full">{reaction}</div>
      )}
    </div>
  );
};
