import { useState } from "react";
import { useNavigate } from "react-router";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { MyListsDocument, useCreateListMutation } from "../../generated/graphql";

export const CreateList: React.FC = () => {
  const navigate = useNavigate();

  const [createLabel, setCreateLabel] = useState("");
  const [createDescription, setCreateDescription] = useState("");
  const [createList] = useCreateListMutation();

  return (
    <div className="flex flex-col gap-4 max-w-2xl mx-auto p-4 w-full">
      <h1 className="text-xl">Create New List</h1>
      <Input placeholder="New List Title" value={createLabel} onChange={(e) => setCreateLabel(e.target.value)} />
      <Textarea
        value={createDescription}
        placeholder="Description (optional)"
        onChange={(e) => setCreateDescription(e.target.value)}
      />
      <div className="gap-4 grid grid-cols-2">
        <Button
          variant="secondary"
          size="lg"
          className="flex-auto"
          onClick={() => {
            navigate("/mystuff");
          }}
        >
          Cancel
        </Button>
        <Button
          size="lg"
          className="flex-auto"
          disabled={!createLabel}
          onClick={(e) => {
            createList({
              variables: { input: { label: createLabel, description: createDescription, public: true } },
              onCompleted: (res) => {
                navigate("/mystuff");
              },
              refetchQueries: [MyListsDocument],
            });
          }}
        >
          Create List
        </Button>
      </div>
    </div>
  );
};
