import { useIsLoggedIn } from "@/auth/user";
import { buttonClassNames, ThreeDotMenu } from "@/components/three-dot-menu";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { useAppBack, useAppNavigate } from "@/hooks/use-app-navigate";
import {
  ListFragment,
  ListRelationship,
  MyListsDocument,
  MySavedItemsDocument,
  useUpdateListMutation,
  useUpdateListRelationshipMutation,
} from "../../generated/graphql";

interface Props {
  list: ListFragment;
}

export const ListHeader: React.FC<Props> = ({ list }) => {
  const goBack = useAppBack();
  const navigate = useAppNavigate();
  const { toast } = useToast();
  const loggedIn = useIsLoggedIn();

  const [updateList, { loading: updating }] = useUpdateListMutation();
  const [updateRelationship, { loading: settingFollower }] = useUpdateListRelationshipMutation();

  const { relationship, public: isPublic } = list;
  const owner = relationship === ListRelationship.Owner;
  const following = relationship !== ListRelationship.Visitor;
  const loading = updating || settingFollower;

  const toggleVisibility = () => {
    updateList({ variables: { input: { listId: list.id, public: !isPublic } } });
  };

  const toggleFollowing = () => {
    updateRelationship({ variables: { input: { listId: list.id, following: !following } } });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => toast({ title: "Link Copied to clipboard!" }));
  };

  return (
    <>
      <div className="fixed bg-gradient-to-r from-transparent to-primary inset-0 mix-blend-hard-light opacity-25 pointer-events-none" />
      <div className="fixed bg-gradient-to-l from-transparent to-foreground inset-0 mix-blend-hard-light opacity-25 pointer-events-none" />
      <div className="fixed bg-gradient-to-b from-transparent to-background inset-0 pointer-events-none" />
      <nav className="flex gap-6 inset-0 items-center justify-between px-6 sticky top-0 w-full z-10 min-h-20 pt-2 bg-gradient-to-b from-background to-transparent from-75%">
        <button className={buttonClassNames} onClick={goBack}>
          <Icon icon="arrow_back" />
        </button>
        <div className="flex flex-auto flex-col items-center max-w-xl flex-wrap">
          <h1 className="font-sans-narrow font-[200] leading-none text-5xl text-center tracking-[0] uppercase max-w-full overflow-ellipsis">
            {list.label}
          </h1>
          <Button variant="ghost" onClick={() => navigate(`/profile/${list.username}`)}>
            <h2>by @{list.username}</h2>
          </Button>
          {owner ? (
            <>
              {!isPublic && <p>Private (only visible to you)</p>}
              <Button
                className="text-inherit mt-2 w-full bg-secondary"
                onClick={() => {
                  if (isPublic) {
                    copyLink();
                  } else {
                    toggleVisibility();
                  }
                }}
              >
                {loading ? <Loading label="" /> : isPublic ? "Share" : "Make Public"}
              </Button>
            </>
          ) : (
            <Button
              className="text-inherit mt-2 w-full bg-secondary"
              onClick={() => {
                if (loggedIn) {
                  if (following) {
                    copyLink();
                  } else {
                    toggleFollowing();
                  }
                } else {
                  navigate(`/login?listId=${list.id}`);
                }
              }}
            >
              {loading ? <Loading label="" /> : following ? "Share" : "Follow"}
            </Button>
          )}
        </div>
        {loggedIn ? (
          <ThreeDotMenu
            isPublic={list.public}
            setPublic={
              owner ? (val) => updateList({ variables: { input: { listId: list.id, public: val } } }) : undefined
            }
            following={following}
            setFollowing={
              !owner
                ? (val) => updateRelationship({ variables: { input: { listId: list.id, following: val } } })
                : undefined
            }
            deleteFn={
              owner
                ? () =>
                    updateList({
                      variables: { input: { listId: list.id, shouldDelete: true } },
                      refetchQueries: [MySavedItemsDocument, MyListsDocument],
                      onCompleted: goBack,
                    })
                : undefined
            }
          />
        ) : (
          <div className="h-full" />
        )}
      </nav>
    </>
  );
};
