import { useEffect } from "react";
import { Outlet } from "react-router";
import { useSearchParams } from "react-router-dom";
import { setAuthData } from "../auth/atoms";

import { Nav } from "./nav";
import { SearchPanel } from "./search/search-panel";
import { Viewport } from "./viewport";

export const SavorLayout = () => (
  <>
    <BaseLayout />
    <Nav />
    <SearchPanel />
  </>
);

export const BaseLayout = () => {
  const [searchParams] = useSearchParams();

  // Helps user be logged in from embedded mobile views
  const authToken = searchParams.get("authToken");

  useEffect(() => {
    if (authToken) {
      setAuthData({ token: { accessToken: authToken }, shouldRemember: true });
    }
  }, [authToken]);

  return (
    <>
      <main className="flex flex-col min-h-dvh pb-36">
        <Outlet />
      </main>
      <Viewport />
    </>
  );
};
