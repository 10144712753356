import { FormProvider, useForm } from "react-hook-form";
import { useCreateRecommendationMutation } from "../../../generated/graphql";
import { useAppBack } from "../../hooks/use-app-navigate";
import { RecInputForm } from "./rec-input-form";

interface Props {
  // close: () => void;
}

export interface RecFormSchema {
  title: string;
  text: string;
  sentiment: number;
  imageUrl?: string;
  entityId?: string;
}

export const CreateRecPanel: React.FC<Props> = ({}) => {
  const goBack = useAppBack();
  const recForm = useForm<RecFormSchema>();
  const [saveRec, { loading: saving }] = useCreateRecommendationMutation();

  return (
    <>
      <FormProvider {...recForm}>
        <RecInputForm
          saving={saving}
          form={recForm}
          onSubmit={(vals) =>
            saveRec({
              variables: {
                input: vals,
              },
              onCompleted: goBack,
            })
          }
        />
      </FormProvider>
    </>
  );
};
