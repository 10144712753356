// Make sure Material Symbols and Rounded are selected and then snake case the

export type IconString =
  | "account_circle"
  | "action_key"
  | "add"
  | "add_circle"
  | "add_a_photo"
  | "arrow_back"
  | "arrow_forward"
  | "campaign"
  | "chat"
  | "check"
  | "check_circle"
  | "circle"
  | "close"
  | "copy_all"
  | "delete"
  | "description"
  | "edit_note"
  | "error"
  | "favorite"
  | "format_list_bulleted"
  | "forum"
  | "grid_on"
  | "home"
  | "info"
  | "ios_share"
  | "lightbulb"
  | "liquor"
  | "keyboard_arrow_down"
  | "keyboard_return"
  | "mail"
  | "more_horiz"
  | "public"
  | "reviews"
  | "search"
  | "send"
  | "thumb_up_alt"
  | "thumb_up_off_alt"
  | "thumb_down_alt"
  | "thumb_down_off_alt"
  | "visibility"
  | "visibility_off";

export const Icon = ({ icon, className }: { icon: IconString; className?: string }) => (
  <span className={`icon aspect-square flex-none ${className ?? ""}`} data-icon={icon}>
    {icon}
  </span>
);
