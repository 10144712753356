import { useParams } from "react-router";

import { useLoadUserQuery, useSavedItemsForUserQuery, useUserListsQuery } from "../../generated/graphql";
import { useMyAccount } from "./use-my-account";
import { useSavor } from "./use-savor";

export const useProfileUser = () => {
  const { username: usernameParam } = useParams();
  const { savedItems, myLists, itemsLoading } = useSavor();
  const myAccount = useMyAccount();
  const username = usernameParam ?? myAccount?.username;
  const isMe = username === myAccount?.username;

  const { data: { loadUser: otherUser } = {}, loading: userLoading } = useLoadUserQuery({
    variables: { username: username! },
    skip: isMe || !username,
    fetchPolicy: "cache-first",
  });
  const { data: { savedItemsForUser: otherUserItems } = {}, loading: userItemsLoading } = useSavedItemsForUserQuery({
    variables: { userId: otherUser?.id! },
    skip: isMe || !otherUser?.id,
    fetchPolicy: "cache-first",
  });
  const { data: { userLists: otherUserLists } = {}, loading: listsLoading } = useUserListsQuery({
    variables: { userId: otherUser?.id! },
    skip: isMe || !otherUser?.id,
    fetchPolicy: "cache-first",
  });

  return {
    isMe,
    user: isMe ? myAccount : otherUser,
    items: isMe ? savedItems : otherUserItems,
    lists: isMe ? myLists : otherUserLists,
    loading: itemsLoading || userLoading || userItemsLoading || listsLoading,
  };
};
