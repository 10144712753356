import * as Tabs from "@radix-ui/react-tabs";
import { usePalette } from "color-thief-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";

import { ImageWithFallback } from "@/components/image-with-fallback";
import { useSavor } from "@/hooks/use-savor";
import { useEntityDetailsQuery } from "../../generated/graphql";
import { EntityAutoAddToList } from "./entity-auto-add-to-list";
import { EntityDetails } from "./entity-details";
import { EntityFooter } from "./entity-footer";
import { EntityHeader } from "./entity-header";
import { EntityLinks } from "./entity-links";
import { EntityNotes } from "./entity-notes";
import { EntityRecipe } from "./entity-recipe";

export interface TargetListProps {
  entityId?: string;
  fromUsername?: string;
  recommendersNote?: string;
  mobile?: boolean;
}

export const Entity: React.FC<TargetListProps> = ({
  entityId: entityIdProp,
  fromUsername: fromUsernameProp,
  recommendersNote,
  mobile,
}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { savedItems } = useSavor();
  const entityId = entityIdProp ?? params.entityId;
  const savedItem = savedItems.find((si) => si.entity?.id === entityId);
  const [tabValue, setTabValue] = useState(savedItem ? "activity" : "details");
  const fromUsername = fromUsernameProp ?? searchParams.get("fromUsername") ?? undefined;

  useEffect(() => {
    // The saveditem id will only change when it is saved (from undefined to defined)
    if (savedItem) {
      setTabValue("activity");
    } else {
      setTabValue("details");
    }
  }, [savedItem?.id]);

  const { data: { entityDetails } = {} } = useEntityDetailsQuery({
    variables: { entityId: entityId! },
    skip: !entityId,
  });

  const {
    data: [primary, fg, bg] = [
      [], // default bg HSL
      [], // default fg HSL
      [], // default primary HSL
    ],
  } = usePalette(entityDetails?.imageUrl || "", 3, "hslArray", { crossOrigin: "", quality: 10 });

  if (!entityId || !entityDetails) {
    return null;
  }

  return (
    <>
      <Helmet>
        <meta property="title" content={`${entityDetails.title} on Savor`} />
        <meta property="og:title" content={`${entityDetails.title} on Savor`} />
        {entityDetails.imageUrl && <meta property="og:image" content={entityDetails.imageUrl} />}
        {bg[0] && (
          <style type="text/css">{`
          :root {
            --background: ${bg[0]} 32% 16%;
            --foreground: ${fg[0]} 48% 72%;
            --border: ${bg[0]} 32% 48%;
            --primary: ${primary[0]} 72% 80%;
          }
          `}</style>
        )}
        <title>{entityDetails.title} on Savor</title>
      </Helmet>
      <EntityAutoAddToList entityId={entityId} />
      <EntityHeader entity={entityDetails} mobile={mobile} />
      <ImageWithFallback
        src={entityDetails.imageUrl}
        className="max-h-48 max-w-72 mx-auto mt-4 mb-6 object-contain rounded z-30"
      />
      <EntityLinks entity={entityDetails} />
      <Tabs.Root value={tabValue} className="relative">
        <Tabs.Content value="activity">
          <EntityNotes entity={entityDetails} />
        </Tabs.Content>
        <Tabs.Content value="details">
          <EntityDetails entity={entityDetails} fromUsername={fromUsername} recommendersNote={recommendersNote} />
        </Tabs.Content>
        {entityDetails.recipeUrl && (
          <Tabs.Content value="recipe">
            <EntityRecipe entity={entityDetails} />
          </Tabs.Content>
        )}
        <EntityFooter entity={entityDetails} fromUsername={fromUsername} setTabValue={setTabValue} />
      </Tabs.Root>
    </>
  );
};

export const EntitySection = ({ children, header }: { children: React.ReactNode; header?: string }) => (
  <section className="border-t border-border/10 p-8 pt-6">
    {header && (
      <header className="font-medium mb-4 text-center text-primary/50 text-2xs tracking-widest uppercase">
        {header}
      </header>
    )}
    <div className="flex flex-col gap-1 max-w-lg mx-auto">{children}</div>
  </section>
);
