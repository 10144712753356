import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { FormProvider, useForm, UseFormReturn, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { authDataAtom } from "@/auth/atoms";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useAppNavigate } from "@/hooks/use-app-navigate";
import {
  MyListsDocument,
  MySavedItemsDocument,
  useConfirmCodeMutation,
  useRequestCodeMutation,
  useSaveItemMutation,
  useUpdateListRelationshipMutation,
} from "../../generated/graphql";

// TODO: This is a copy of utils/slugify.ts in api
// Should move this to a shared utility package

export const slugify = <T extends string | undefined>(label: T, opts?: { spaceChar?: string }): T =>
  label
    ?.toLowerCase()
    ?.trim()
    ?.replaceAll(" ", opts?.spaceChar ?? "_") as T;

interface FormSchema {
  code: string;
  name?: string;
  email?: string;
  username?: string;
}

export const LoginCard: React.FC = () => {
  const navigate = useAppNavigate();
  const [params, setParams] = useSearchParams();
  const { toast } = useToast();
  const [, setAuthData] = useAtom(authDataAtom);
  const [needsName, setNeedsName] = useState(false);

  const phoneParam = params.get("phone");
  const codeParam = params.get("code");
  const listId = params.get("listId");
  const shareId = params.get("shareId");
  const entityId = params.get("entityId");
  const fromUsername = params.get("fromUsername");

  const [phoneInput, setPhoneInput] = useState(phoneParam ?? "");

  const [requestCode] = useRequestCodeMutation();
  const [confirmCode] = useConfirmCodeMutation();
  const [saveItem] = useSaveItemMutation();
  const [updateListRelation] = useUpdateListRelationshipMutation();

  const submitPhone = () => {
    if (phoneInput) {
      setParams((prior) => {
        return { ...Object.fromEntries(prior), phone: phoneInput };
      });
    }
  };

  const submitCode = (values: FormSchema) => {
    confirmCode({
      variables: { input: { phone: phoneParam!, ...values } },
      onError: () => {
        toast({ title: "Invalid Code", variant: "destructive" });
      },
      onCompleted: (data) => {
        setAuthData({
          token: { accessToken: data.confirmCode.accessToken },
          shouldRemember: true,
        });
        if (entityId) {
          saveItem({
            variables: { input: { entityId, fromShareId: shareId, fromUsername } },
            refetchQueries: [MySavedItemsDocument, MyListsDocument],
          }).then(() => navigate(`/entity/${entityId}`));
        } else if (listId) {
          updateListRelation({ variables: { input: { listId, following: true } } }).then(() =>
            navigate(`/lists/${listId}`)
          );
        } else {
          navigate("/home");
        }
      },
    });
  };

  useEffect(() => {
    if (phoneParam && !codeParam) {
      requestCode({
        variables: { input: { phone: phoneParam } },
        fetchPolicy: "network-only",
        onCompleted: (res) => setNeedsName(!res.requestCode.name),
      });
    }
    if (phoneParam && codeParam) {
      submitCode({ code: codeParam });
    }
  }, [phoneParam, codeParam]);

  const codeForm = useForm<FormSchema>();

  return (
    <>
      {!phoneParam ? (
        <Card className="flex flex-col max-w-72 p-4 w-full gap-2">
          <h4>Enter Your Phone to Get Started</h4>
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder="2138675309"
            value={phoneInput}
            onChange={(e) => setPhoneInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitPhone();
              }
            }}
          />
          {phoneInput && <Button onClick={submitPhone}>Submit</Button>}
        </Card>
      ) : (
        <Card className="max-w-72 p-4 w-full">
          <FormProvider {...codeForm}>
            <SubmitCodeForm form={codeForm} submit={submitCode} needsName={needsName} />
          </FormProvider>
        </Card>
      )}
    </>
  );
};

const SubmitCodeForm: React.FC<{
  form: UseFormReturn<FormSchema>;
  submit: (values: FormSchema) => void;
  needsName: boolean;
}> = ({ form, submit, needsName }) => {
  const { name } = useWatch<FormSchema>();
  return (
    <form
      id="add-code"
      className="flex flex-col gap-4"
      onSubmit={(e) => {
        submit({
          code: form.getValues("code"),
          name: form.getValues("name"),
          email: form.getValues("email"),
          username: form.getValues("username"),
        });
        e.preventDefault();
      }}
    >
      {needsName && (
        <>
          <h4>What's Your Name?</h4>
          <Input
            placeholder="Your name as you'd like it displayed on your profile"
            required
            {...form.register("name")}
          />
          <h4>Pick a Username</h4>
          <Input
            placeholder="Your handle: How you'll show up in threads and urls"
            required
            defaultValue={slugify(name, { spaceChar: "" })}
            {...form.register("username")}
          />
          <h4>Email Address</h4>
          <Input placeholder="john@doe.com" {...form.register("email")} />
        </>
      )}
      <h4>Enter Your Six Digit Code</h4>
      <Input
        autoComplete="one-time-code"
        inputMode="numeric"
        pattern="[0-9]*"
        placeholder="XXXXXX"
        {...form.register("code")}
      />
      <Button type="submit">Submit</Button>
    </form>
  );
};
