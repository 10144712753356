import { useAtomValue } from "jotai";

import { MyAccountDocument, MyAccountQuery, MyAccountQueryVariables } from "../../generated/graphql";
import { apolloClient } from "../../graphql/client";
import { getAccessToken, jwtDataAtom } from "./atoms";

export async function getAuthedUser() {
  const accessToken = getAccessToken();

  if (accessToken) {
    // fetch account if we have an access token
    const response = await apolloClient
      .query<MyAccountQuery, MyAccountQueryVariables>({
        query: MyAccountDocument,
        fetchPolicy: "cache-first",
      })
      .catch(() => {
        console.error("Error validating user token. Logging out");
      });
    // set correct user info if we have a valid response
    if (response?.data?.myAccount) {
      return {
        user: {
          id: response.data.myAccount.id,
          email: response.data.myAccount.email,
          phone: response.data.myAccount.phone,
        },
      };
    }
  }

  return null;
}

export function useIsLoggedIn(): boolean {
  const jwtData = useAtomValue(jwtDataAtom);
  return Boolean(jwtData && !jwtData.hasExpired);
}
