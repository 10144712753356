import { useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { ImageWithFallback } from "../../components/image-with-fallback";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { useDebounce } from "../../hooks/use-debounce";
import { useMyAccount } from "../../hooks/use-my-account";
import { SearchResult } from "../../hooks/use-savor";
import { RecImage } from "../rec-image";
import { RecSlider } from "../rec-slider";
import { useSentiment } from "../use-sentiment";
import { RecFormSchema } from "./create-rec-panel";
import { EntityAutocomplete } from "./entity-autocomplete";
import { EntityResult } from "./entity-result";

interface Props {
  form: UseFormReturn<RecFormSchema>;
  onSubmit: (vals: RecFormSchema) => void;
  saving: boolean;
}

export const RecInputForm: React.FC<Props> = ({ form, onSubmit, saving }) => {
  const user = useMyAccount();
  const { calculate } = useSentiment();
  const [entityResult, setEntityResult] = useState<SearchResult>();
  const [preventEntity, setPreventEntity] = useState(false);
  const { title, sentiment, text } = useWatch(form);
  const { debouncedRequest: updateSentiment } = useDebounce((t) => form.setValue("sentiment", calculate(t)), [], 300);

  console.log({ entityResult });
  if (!user) {
    return null;
  }

  return (
    <form
      id="rec-input"
      className="flex flex-col gap-4 w-full max-w-xl m-auto px-4"
      onSubmit={(e) => {
        onSubmit({
          title: form.getValues("title"),
          text: form.getValues("text"),
          imageUrl: form.getValues("imageUrl"),
          sentiment: form.getValues("sentiment"),
          entityId: entityResult?.entityId ?? undefined,
        });
        e.preventDefault();
      }}
    >
      <>
        <h4>What are you recommending?</h4>
        {entityResult ? (
          <EntityResult
            result={entityResult}
            onReject={() => {
              setEntityResult(undefined);
              setPreventEntity(true);
            }}
          />
        ) : (
          <Input placeholder="Title" required {...form.register("title")} />
        )}
        {!preventEntity && !entityResult && (
          <EntityAutocomplete
            term={title ?? ""}
            onConfirm={(res) => {
              console.log("res!");
              form.setValue("title", res.title);
              setEntityResult(res);
            }}
            onReject={() => setPreventEntity(true)}
          />
        )}
        <div className="flex flex-row gap-2 h-min">
          {entityResult?.imageUrl && <ImageWithFallback src={entityResult.imageUrl} className="w-40" />}
          <RecImage
            className="max-w-40"
            user={user}
            editable={true}
            setSaveableImage={(uri) => form.setValue("imageUrl", uri)}
          />
        </div>
        <Textarea
          placeholder="Your Review"
          required
          {...form.register("text")}
          rows={15}
          onChange={(e) => {
            form.register("text").onChange(e);
            updateSentiment(e.target.value);
          }}
        />
        {text && (
          <>
            <div>
              <h4>Sentiment</h4>
              <p className="text-gray-400 italic text-xs">
                We took a guess based on your review. You can edit this at any time
              </p>
            </div>

            <RecSlider
              className="mt-0"
              editable={true}
              sentiment={sentiment ?? 0}
              onValueChange={(val) => form.setValue("sentiment", val)}
            />
          </>
        )}
      </>
      <Button disabled={saving} type="submit">
        Submit
      </Button>
    </form>
  );
};
