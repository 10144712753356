import { useLocation, useNavigate } from "react-router";

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const state = { from: location.pathname };
  return (to: string) => {
    navigate(to, {
      state,
    });
  };
};

export const useAppBack = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const from = state?.from;
  return () => {
    if (from && from !== pathname) {
      navigate(state?.from);
    } else {
      navigate("/home");
    }
  };
};
