import { orderBy } from "lodash";

import { MegaButton } from "@/components/fake-search";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Select, SelectContent, SelectItem, SelectValue } from "@/components/ui/select";
import { SelectTrigger } from "@radix-ui/react-select";
import { RecommendationFragment, useLoadRecommendationsQuery } from "../../generated/graphql";
import { useAppNavigate } from "../hooks/use-app-navigate";
import { Recommendation } from "./recommendation";

interface Props {
  filterFn?: (rec: RecommendationFragment) => boolean;
}

export const Recommendations: React.FC<Props> = ({ filterFn }) => {
  const navigate = useAppNavigate();
  const { data: { loadRecommendations: recommendations } = {}, loading } = useLoadRecommendationsQuery();

  if (loading) {
    return <Spinner />;
  }

  const orderedRecs = orderBy(recommendations, (r) => r.updatedAt, "desc").filter((r) => filterFn?.(r) ?? true);

  return (
    <>
      <nav className="bg-background flex h-16 items-center justify-start px-4 shadow sticky top-0 z-20">
        <div>
          <Select value={window.location.pathname} onValueChange={(v) => navigate(v)}>
            <SelectTrigger className="bg-foreground/5 flex gap-3 items-center pl-4 pr-3 py-2 rounded text-xl outline-0">
              <SelectValue />
              <Icon icon="keyboard_arrow_down" className="text-2xl" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="/recs">All</SelectItem>
              <SelectItem value="/recs/cocktails">Cocktails</SelectItem>
              <SelectItem value="/recs/movies">Movies</SelectItem>
              <SelectItem value="/recs/baby">Baby Stuff</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </nav>
      <div className="flex flex-col gap-2 mt-4 mx-auto">
        {orderedRecs.map((rec) => (
          <Recommendation key={rec.id} rec={rec} />
        ))}
      </div>
      <MegaButton
        label="Post a new Recommendation"
        leftIcon="campaign"
        rightIcon="arrow_forward"
        onClick={() => navigate("/create-rec")}
      />
    </>
  );
};
