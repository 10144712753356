import { useAppNavigate } from "@/hooks/use-app-navigate";
import { SavedItemFragment } from "../../generated/graphql";
import { Thumbnail } from "./thumbnail";

interface Props {
  items: SavedItemFragment[];
  fromUsername?: string;
}

export const Items: React.FC<Props> = ({ items, fromUsername }) => {
  const navigate = useAppNavigate();

  return (
    <>
      {items.map((si, key) => (
        <Thumbnail
          key={si.id}
          alt={si.title}
          src={si.entity?.thumbnailUrl ?? undefined}
          reaction={si.reaction}
          onClick={(e) => {
            if (si.entity) {
              navigate(`/entity/${si.entity.id}?fromUsername=${fromUsername}`);
            }
            e.stopPropagation();
          }}
        />
      ))}
    </>
  );
};

export const ItemsContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="bg-background gap grid grid-cols-[repeat(auto-fill,minmax(8rem,1fr))] p">{children}</div>;
};
