import { useSavor } from "@/hooks/use-savor";
import { Icon } from "../ui/icon";
import { Input } from "../ui/input";
import { Spinner } from "../ui/loading";

interface Props {
  search: (query: string) => void;
  loading: boolean;
  onChange?: (val: string) => void;
}

export const SearchBar: React.FC<Props> = ({ search, loading, onChange }) => {
  const { focusInput, inputRef, searchBarText, setSearchBarText, searchResponse } = useSavor();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (searchBarText) {
      search(searchBarText);

      // We know that we are focused on the SavorBar, but want to close the iOS keyboard now
      (document.activeElement as any).blur();
    }

    e.preventDefault();
  };

  return (
    <aside className="bg-background bottom-4 h-16 m-4 rounded-xl shadow-xl sticky z-50">
      <form id="savor" onSubmit={handleSubmit} action=".">
        <Input
          ref={inputRef}
          type="search"
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              handleSubmit(event as any);
            }
          }}
          className="bg-transparent border-none focus-visible:ring-primary focus-visible:ring-0 focus-visible:ring-offset-0 h-16 px-14 rounded-none text-base"
          placeholder={!searchResponse ? "Try one of the above or search for anything" : "What brings you here today?"}
          value={searchBarText}
          onChange={(e) => {
            setSearchBarText(e.target.value);
            onChange?.(e.target.value);
          }}
        />
      </form>
      <div className="absolute flex h-8 items-center justify-center left-3 top-4 w-8">
        {loading ? <Spinner /> : <Icon icon="search" className="text-lg text-muted-foreground" />}
      </div>
      {!loading && searchBarText !== "" && (
        <button
          className="absolute aspect-square bottom-0 flex items-center justify-center right-0 top-0 text-muted-foreground text-xl"
          onTouchStart={() => {
            setSearchBarText("");
            focusInput();
          }}
          onClick={() => {
            setSearchBarText("");
            focusInput();
          }}
        >
          <Icon icon="close" />
        </button>
      )}
    </aside>
  );
};
