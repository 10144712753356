import { compact, orderBy, uniqBy } from "lodash";
import { useState } from "react";

import { Drawer, drawerIcon, DrawerRow } from "@/components/drawer";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import { SavedItemFragment, useMySourcesQuery, useUpdateSavedItemMutation } from "../../generated/graphql";

interface Props {
  savedItem?: SavedItemFragment;
  showSources?: boolean;
  handleClose: () => void;
}

export const Sources: React.FC<Props> = ({ savedItem, showSources, handleClose }) => {
  const { data: { mySources = [] } = {} } = useMySourcesQuery();
  const [updateItem] = useUpdateSavedItemMutation();
  const [loadingId, setLoadingId] = useState<string>();
  const [addSource, setAddSource] = useState(false);
  const [creatingSource, setCreatingSource] = useState(false);
  const [sourceName, setSourceName] = useState<string>();

  if (!savedItem) {
    return null;
  }

  const hasSource = (sourceId?: string) => savedItem.sources.some((s) => s.id === sourceId);

  const toggleSourceAttribution = ({ sourceId, sourceLabel }: { sourceId?: string; sourceLabel: string }) => {
    setLoadingId(sourceId);
    if (!sourceId) {
      setCreatingSource(true);
    }
    updateItem({
      variables: {
        input: {
          itemId: savedItem.id,
          ...(hasSource(sourceId) ? { removeSource: sourceId } : { addSource: sourceLabel }),
        },
      },
      onCompleted: () => {
        setLoadingId(undefined);
        setCreatingSource(false);
        if (!sourceId) {
          setSourceName(undefined);
        }
      },
      updateQueries: {
        MySources: (prev, { mutationResult }) => {
          if (!mutationResult.data) {
            return prev;
          }
          return {
            mySources: compact(
              uniqBy([...prev.mySources, ...mutationResult.data.updateSavedItem.sources], (s) => s.id)
            ),
          };
        },
      },
    });
  };

  const { sources } = savedItem;
  const orderedSources = orderBy(mySources, (s) => s.label, "asc");

  return (
    <Drawer
      handleClose={() => {
        setAddSource(false);
        setSourceName("");
        handleClose();
      }}
      title="Sources"
      visible={showSources}
    >
      <div className="border-background/5 border-b">
        {addSource ? (
          <div className="flex items-center px-6">
            <Icon icon="add" className={drawerIcon} />
            {creatingSource ? (
              <Loading className="w-full h-14 text-lg px-6" />
            ) : (
              <Input
                placeholder="Source"
                autoFocus
                value={sourceName}
                onChange={(e) => setSourceName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (sourceName) {
                      toggleSourceAttribution({ sourceLabel: sourceName });
                    }
                    e.preventDefault();
                  }
                }}
                className="bg-inherit border-0 flex-auto h-14 px-6 py-3 placeholder:text-background/50 text-inherit text-lg focus-visible:ring-0 focus-visible:ring-offset-0"
              />
            )}
            {sourceName && sourceName.length > 0 && <Icon icon="keyboard_return" className={drawerIcon} />}
          </div>
        ) : (
          <button
            className="flex flex-auto gap-6 h-14 items-center px-6 text-left text-lg"
            onClick={() => setAddSource(true)}
          >
            <Icon icon="add" className={drawerIcon} />
            <span className="flex-auto font-medium">Add Source</span>
          </button>
        )}
      </div>
      <div className="flex-auto overflow-y-auto py-6 space-y-6">
        {sources[0] && (
          <div>
            <h5 className="opacity-50 px-6 py-2">Recommended By</h5>
            {sources.map((source) => (
              <DrawerRow
                key={source.id}
                id={source.id}
                label={source.label}
                checked={true}
                loading={loadingId === source.id}
                handleClick={() => toggleSourceAttribution({ sourceId: source.id, sourceLabel: source.label })}
              />
            ))}
          </div>
        )}
        {orderedSources[0] && (
          <div>
            <h5 className="opacity-50 px-6 py-2">All Sources</h5>
            {orderedSources.map((source) => (
              <DrawerRow
                key={source.id}
                id={source.id}
                label={source.label}
                checked={hasSource(source.id)}
                loading={loadingId === source.id}
                handleClick={() => toggleSourceAttribution({ sourceId: source.id, sourceLabel: source.label })}
              />
            ))}
          </div>
        )}
      </div>
    </Drawer>
  );
};
