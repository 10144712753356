import { useEffect, useState } from "react";
import { useSearchV2LazyQuery } from "../../../generated/graphql";
import { useDebounce } from "../../hooks/use-debounce";
import { SearchResult, useSavor } from "../../hooks/use-savor";
import { EntityResult } from "./entity-result";

interface Props {
  term: string;
  onConfirm: (val: SearchResult) => void;
  onReject: () => void;
}

export const EntityAutocomplete: React.FC<Props> = ({ term, onConfirm, onReject }) => {
  const { filteredContent } = useSavor();
  const [search, { data, loading }] = useSearchV2LazyQuery();
  const [searchResult, setSearchResult] = useState<SearchResult>();

  const termTokens = term.split(/\s/).map((t) => t.toLowerCase());

  const { debouncedRequest: debouncedSearch } = useDebounce(
    (term: string) =>
      search({
        variables: { term },
        onCompleted: (res) => {
          const filteredResults = [
            ...(res?.searchV2.myResults ?? []),
            ...(res?.searchV2.networkResults ?? []),
            ...(res?.searchV2.internetResults ?? []),
          ].filter((r) => termTokens.some((t) => r.title.toLowerCase().includes(t)));
          setSearchResult(filteredResults[0]);
        },
      }),
    [],
    300
  );

  useEffect(() => {
    if (!term) {
      setSearchResult(undefined);
    } else if (term.length > 3) {
      if (filteredContent.length > 0) {
        setSearchResult(filteredContent[0].entity ?? undefined);
      } else {
        debouncedSearch(term);
      }
    }
  }, [term.trim()]);

  console.log({ searchResult });
  return <EntityResult loading={loading} result={searchResult} onConfirm={onConfirm} onReject={onReject} />;
};
