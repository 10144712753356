import { useSavor } from "@/hooks/use-savor";
import { Icon, IconString } from "../ui/icon";

export const SearchIntro: React.FC = () => {
  const { focusInput } = useSavor();

  return (
    <div className="flex flex-row flex-wrap justify-center mt-2 h-full items-start">
      <SearchIntroCard message="Find a movie to watch" query="movie for tonight" />
      <SearchIntroCard message="Make a cocktail with what you have" query="cocktails with rum and campari" />
      <SearchIntroCard message="Find a restaurant near me" query="restaurants in NYC" />
      <SearchIntroCard
        icon="public"
        message="Save a website or listicle"
        query="https://www.timeout.com/newyork/restaurants/100-best-new-york-restaurants"
      />
      <SearchIntroCard icon="search" message="Save something you encoutered today" onClick={focusInput} />
    </div>
  );
};

const SearchIntroCard: React.FC<{ message: string; query?: string; icon?: IconString; onClick?: () => void }> = ({
  message,
  query,
  icon,
  onClick,
}) => {
  const { setSearchBarText, inputRef } = useSavor();

  return (
    <div
      className="rounded bg-gray-100 p-12 max-w-2xl w-full my-2 mx-4 cursor-pointer max-h-32"
      onClick={
        onClick ??
        (() => {
          setSearchBarText(query ?? message);
          // TODO: Super hacky, but a proof of concept
          setTimeout(
            () =>
              document.getElementById("savor")?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true })),
            // Delay enough to let the value update
            200
          );
        })
      }
    >
      <div className="flex flex-row items-center gap-4">
        <Icon className="text-2xl" icon={`${icon ?? "lightbulb"}`} /> {message}
      </div>
    </div>
  );
};
