import { NavLink as Link, useParams } from "react-router-dom";

import { useMyAccount } from "@/hooks/use-my-account";
import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";
import { Icon, IconString } from "./ui/icon";

export const Nav = () => {
  const loggedInUser = useMyAccount();
  const params = useParams();
  const { entityId } = params;

  if (!loggedInUser || entityId) {
    return null;
  }

  const photo = loggedInUser.profilePhoto ? (
    <img
      src={loggedInUser.profilePhoto}
      className="h-[20px] object-cover rounded-full w-[20px] ring-primary ring-1 ring-offset-1"
    />
  ) : null;

  return (
    <NavContainer>
      <NavLink to="/home" icon="home" title="Home" />
      <NavLink to="/mystuff" icon="action_key" title="My Stuff" />
      <NavLink to="/profile" icon="account_circle" title="Profile" image={photo} />
    </NavContainer>
  );
};

export const NavContainer = ({
  id,
  children,
  className,
}: {
  id?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const { standalone } = useSavor();

  return (
    <nav
      className={cn(
        "bottom-0 fixed flex items-end h-36 justify-center left-0 pointer-events-none right-0 z-30",
        standalone && "pb-8",
        className
      )}
      id={id}
    >
      <div className="nav-backdrop backdrop-blur-sm" />
      <div className="nav-backdrop backdrop-blur-md top-5" />
      <div className="nav-backdrop backdrop-blur-lg top-10" />
      <div className="nav-backdrop backdrop-blur-xl top-20" />
      <div className="flex gap-1 items-center h-20 justify-center pointer-events-auto relative z-1 w-full">
        {children}
      </div>
    </nav>
  );
};

const navClassNames =
  "flex flex-col flex-none gap-2 items-center justify-center transition-colors py-2 text-[20px] transition-all duration-300 w-[56px]";

export const NavLink = ({
  className,
  to,
  icon,
  image,
  title,
}: {
  className?: string;
  to: string;
  icon: IconString;
  image?: React.ReactNode;
  title: string;
}) => (
  <Link
    to={to}
    title={title}
    className={({ isActive }) =>
      cn(navClassNames, className, isActive ? "filled text-primary" : "grayscale opacity-60")
    }
  >
    {image ?? <Icon icon={icon} />}
    <span className="text-[9px]">{title}</span>
  </Link>
);

export const NavAnchor = ({
  className,
  href,
  icon,
  title,
}: {
  className?: string;
  href: string;
  icon: IconString;
  title: string;
}) => (
  <a href={href} title={title} className={cn(navClassNames, className)}>
    <Icon icon={icon} />
    <span className="text-[9px]">{title}</span>
  </a>
);

export const NavButton = ({
  className,
  component,
  icon,
  title,
  onClick,
}: {
  className?: string;
  component?: React.ReactNode;
  icon: IconString;
  title: string;
  onClick?: () => void;
}) => (
  <div className={cn(navClassNames, "cursor-pointer text-primary", className)} onClick={onClick}>
    {component ?? <Icon icon={icon} />}
    <span className="text-[9px]">{title}</span>
  </div>
);
