import { ImageWithFallback } from "../../components/image-with-fallback";
import { Icon } from "../../components/ui/icon";
import { Spinner } from "../../components/ui/loading";
import { SearchResult } from "../../hooks/use-savor";

interface Props {
  loading?: boolean;
  result?: SearchResult;
  onConfirm?: (val: SearchResult) => void;
  onReject?: (val: SearchResult) => void;
}

export const EntityResult: React.FC<Props> = ({ loading, result, onConfirm, onReject }) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row gap-4">
        {loading ? <Spinner /> : result ? <ImageWithFallback className="h-8" src={result.imageUrl} /> : null}
        {result && (
          <p>
            {result.title} · ({result.type})
          </p>
        )}
      </div>
      {result && (
        <div className="flex flex-row gap-4">
          {onConfirm && (
            <button
              onClick={(e) => {
                onConfirm(result);
                e.preventDefault();
              }}
            >
              <Icon icon="check_circle" className="text-gray-500" />
            </button>
          )}
          {onReject && (
            <button
              onClick={(e) => {
                onReject(result);
                e.preventDefault();
              }}
            >
              <Icon icon="close" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
