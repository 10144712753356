import { createBrowserRouter, LoaderFunction, Navigate, redirect } from "react-router-dom";
import { setAuthData } from "./auth/atoms";

import { LoginPage } from "./auth/login";
import { getAuthedUser } from "./auth/user";
import { BaseLayout, SavorLayout } from "./components/layout";
import { Entity } from "./entity/entity";
import { SharedEntity } from "./entity/shared-entity";
import { Entolio } from "./entolio/entolio";
import { Error404 } from "./error-404";
import { Home } from "./home/home";
import { List } from "./lists/list";
import { CreateList } from "./mystuff/create-list";
import { MyStuff } from "./mystuff/my-stuff";
import { Profile } from "./profile/profile";
import { CreateRecPanel } from "./recs/create-rec/create-rec-panel";
import { Recommendations } from "./recs/recommendations";

export const authLoader: LoaderFunction = async ({ request }) => {
  const { user } = (await getAuthedUser()) || {};

  const searchParams = new URL(request.url).searchParams;
  const phone = searchParams.get("phone");
  const code = searchParams.get("code");

  if (!user) {
    return redirect(`/login?${phone ? `phone=${encodeURI(phone)}&` : ""}${code ? `code=${code}` : ""}`);
  }
  return null;
};

export const router = createBrowserRouter([
  {
    element: <SavorLayout />,
    children: [
      {
        loader: authLoader,
        children: [
          {
            // Deprecated.. but keeping around in case any bookmarks are set to go here
            path: "savor",
            element: <Navigate to={"/home"} />,
          },
          {
            path: "home",
            element: <Home />,
          },
          {
            path: "mystuff",
            element: <MyStuff />,
          },
          {
            path: "create-list",
            element: <CreateList />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "entolio",
            element: <Entolio />,
          },
          {
            path: "recs",
            children: [
              {
                index: true,
                element: <Recommendations />,
              },
              {
                path: "cocktails",
                element: (
                  <Recommendations
                    filterFn={(r) =>
                      Boolean(
                        `${r.entity.title}${r.entity.type}`.toLowerCase().match(/(alcohol|cocktail|liquor|liqueur)/)
                      )
                    }
                  />
                ),
              },
              {
                path: "movies",
                element: (
                  <Recommendations
                    filterFn={(r) => Boolean(`${r.entity.title}${r.entity.type}`.toLowerCase().match(/(movie|film)/))}
                  />
                ),
              },
              {
                path: "baby",
                element: (
                  <Recommendations
                    filterFn={(r) => Boolean(`${r.entity.title}${r.entity.type}`.toLowerCase().match(/(baby|product)/))}
                  />
                ),
              },
            ],
          },
          {
            path: "create-rec",
            element: <CreateRecPanel />,
          },
        ],
      },
      {
        path: "profile/:username",
        element: <Profile />,
      },
      {
        path: "lists/:listId",
        element: <List />,
      },
    ],
  },
  {
    element: <BaseLayout />,
    children: [
      {
        path: "shared/:shareId",
        element: <SharedEntity />,
      },
      {
        path: "entity/:entityId",
        element: <Entity />,
      },
      {
        path: "m/entity/:entityId",
        element: <Entity mobile={true} />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "logout",
        loader: () => {
          setAuthData(null);
          return null;
        },
        element: <Navigate to="/login" />,
      },
      {
        index: true,
        element: <Navigate to="/login" />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
]);
