import { ImageWithFallback } from "@/components/image-with-fallback";
import { Button } from "@/components/ui/button";
import { useProfileUser } from "@/hooks/use-profile-user";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import ImageUploading, { ImageType } from "react-images-uploading";
import { useUpdateProfilePhotoMutation } from "../../generated/graphql";

export const ProfilePhoto: React.FC = () => {
  const { isMe, user } = useProfileUser();
  const [updatePhoto] = useUpdateProfilePhotoMutation();
  const [tempPhoto, setTempPhoto] = useState<string>();
  const editable = isMe;

  useEffect(() => {
    if (user?.profilePhoto) {
      setTempPhoto(user.profilePhoto);
    }
  }, [user?.profilePhoto]);

  const [showEdit, setShowEdit] = useState(false);
  const editor = useRef<AvatarEditor>(null);

  const onSelectPhoto = (images: ImageType[]) => {
    setTempPhoto(images[0]?.dataURL);
    setShowEdit(true);
  };

  return (
    <div className="max-w-32 w-full">
      {editable && showEdit && tempPhoto ? (
        <>
          <AvatarEditor
            ref={editor}
            width={128}
            height={128}
            image={tempPhoto}
            border={0}
            borderRadius={100}
            scale={1.2}
          />
          <div className="flex flex-row">
            <Button
              variant="default"
              className="w-full"
              onClick={() => {
                if (editor) {
                  const canvasImage = editor.current?.getImage().toDataURL();
                  if (canvasImage) {
                    updatePhoto({ variables: { dataUrl: canvasImage } });
                  }
                  setShowEdit(false);
                }
              }}
            >
              Save
            </Button>
            <Button variant="destructive" onClick={() => setShowEdit(false)}>
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <ImageWithFallback
          src={user?.profilePhoto ?? "/images/tbd"}
          className="rounded-full shadow h-32 w-32 object-cover"
        />
      )}
      {editable && (
        <ImageUploading multiple={false} value={[]} onChange={onSelectPhoto}>
          {({ onImageUpload }) => (
            <Button
              variant="ghost"
              className="w-full text-sm"
              onClick={() => (tempPhoto && !showEdit ? setShowEdit(true) : onImageUpload())}
            >
              {showEdit ? "New Photo" : "Edit Photo"}
            </Button>
          )}
        </ImageUploading>
      )}
    </div>
  );
};
