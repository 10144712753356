import { useEffect } from "react";

import { useIsLoggedIn } from "@/auth/user";
import { useToast } from "@/components/ui/use-toast";
import { useSavor } from "@/hooks/use-savor";
import { MyListsDocument, MySavedItemsDocument, useUpdateSavedItemMutation } from "../../generated/graphql";

export const EntityAutoAddToList: React.FC<{ entityId: string }> = ({ entityId }) => {
  const loggedIn = useIsLoggedIn();
  const { savedItems, targetListId, setTargetListId, setSearchVisible } = useSavor();
  const savedItem = savedItems.find((si) => si.entity?.id === entityId);
  const toaster = useToast();
  const isUnsaved = loggedIn && !savedItem;

  const [rawUpdateSavedItem] = useUpdateSavedItemMutation();
  const updateSavedItem = (options: Parameters<typeof rawUpdateSavedItem>[0]) =>
    rawUpdateSavedItem({
      ...options,
      onCompleted: (res) => {
        options?.onCompleted?.(res);
        setSearchVisible(false);
      },
    });

  useEffect(() => {
    if (targetListId && savedItem && !savedItem.lists.find((t) => t.id === targetListId)) {
      updateSavedItem({
        variables: { input: { itemId: savedItem.id, addToList: targetListId } },
        onCompleted: (res) => {
          toaster.toast({
            title: `Added to "${res.updateSavedItem.lists.find((t) => t.id === targetListId)?.label}"!`,
          });
          setTargetListId(undefined);
        },
        refetchQueries: [MySavedItemsDocument, MyListsDocument],
      });
    }
  }, [targetListId, isUnsaved]);

  return null;
};
