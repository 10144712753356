import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import ImageUploading, { ImageType } from "react-images-uploading";
import { ClassNameValue } from "tailwind-merge";
import { RecommendationFragment } from "../../generated/graphql";
import { ImageWithFallback } from "../components/image-with-fallback";
import { Icon } from "../components/ui/icon";
import { useDebounce } from "../hooks/use-debounce";
import { cn } from "../utils";

interface Props {
  user: RecommendationFragment["user"];
  editable: boolean;
  setSaveableImage: (uri: string) => void;
  image?: string;
  className?: ClassNameValue;
}

export const RecImage: React.FC<Props> = ({ user, editable, setSaveableImage, image, className }) => {
  const [tempPhoto, setTempPhoto] = useState<string | undefined>(image ?? undefined);
  const [selecting, setSelecting] = useState(false);
  const editor = useRef<AvatarEditor>(null);

  const updateImage = () => {
    if (editor) {
      const canvasImage = editor.current?.getImage().toDataURL();
      if (canvasImage) {
        setSaveableImage(canvasImage);
      }
    }
  };

  const { debouncedRequest: debouncedUpdate } = useDebounce(updateImage, [], 400);

  const onSelectPhoto = (images: ImageType[]) => {
    console.log("selected photo?");
    console.log(images);
    setTempPhoto(images[0]?.dataURL);
    setSelecting(true);
  };

  return (
    <ImageUploading multiple={false} value={[]} onChange={onSelectPhoto}>
      {({ onImageUpload, dragProps, isDragging }) => {
        return (
          <div className={cn("relative h-min", className)} style={{ flex: 1, flexShrink: 0 }}>
            {editable && selecting && tempPhoto ? (
              <AvatarEditor
                ref={editor}
                width={128}
                height={128}
                image={tempPhoto}
                border={0}
                borderRadius={50}
                scale={1.2}
                onImageChange={debouncedUpdate}
                onImageReady={debouncedUpdate}
              />
            ) : (
              <div className="group aspect-square bg-white overflow-hidden relative rounded shadow" {...dragProps}>
                {editable && !selecting && (
                  <button
                    className="absolute flex items-center justify-center w-full aspect-square bg-gray-300 bg-opacity-60 rounded text-8xl text-white"
                    onClick={(e) => {
                      onImageUpload();
                      e.preventDefault();
                    }}
                  >
                    {isDragging ? <span>Drop Here!</span> : <Icon icon="add_a_photo" />}
                  </button>
                )}
                <ImageWithFallback src={image} className="object-cover" />
              </div>
            )}
            <ImageWithFallback
              src={user.profilePhoto}
              className="w-1/2 aspect-square absolute rounded-full -bottom-2 -right-2 shadow-md"
            />
          </div>
        );
      }}
    </ImageUploading>
  );
};
