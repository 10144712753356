import { formatDistance } from "date-fns";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Textarea, TextareaProps } from "@/components/ui/textarea";
import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";
import { EntityFragment, useUpsertNoteMutation } from "../../generated/graphql";
import { EntitySection } from "./entity";

export const EntityNotes: React.FC<{ entity: EntityFragment }> = ({ entity }) => {
  const { savedItems } = useSavor();
  const savedItem = savedItems.find((si) => si.entity?.id === entity.id);
  const [note, setNote] = useState("");
  const [editingId, setEditingId] = useState<string>();
  const [editingText, setEditingText] = useState("");
  const [upsertNote] = useUpsertNoteMutation();
  const [refreshing, setRefreshing] = useState(false);
  const [updating, setUpdating] = useState(false);

  const submitNote = () => {
    upsertNote({
      variables: { input: { entityId: entity.id, text: note } },
      onCompleted: () => setRefreshing(false),
    });
    setNote("");
    setRefreshing(true);
  };

  const updateNote = () => {
    upsertNote({
      variables: { input: { noteId: editingId, text: editingText } },
      onCompleted: () => {
        setUpdating(false);
        setEditingId(undefined);
        setEditingText("");
      },
      onError: () => {
        setUpdating(false);
      },
    });
    setUpdating(true);
  };

  const deleteNote = (id: string) => {
    upsertNote({
      variables: { input: { noteId: id, shouldDelete: true } },
    });
  };

  return (
    <>
      {entity.notes[0] && (
        <EntitySection header="Notes">
          {entity.notes.map((c) => {
            const relativeTime = formatDistance(new Date(c.createdAt), new Date(), { addSuffix: true });
            const editing = editingId === c.id;

            return (
              <>
                {editing ? (
                  <CommentArea
                    key={c.id}
                    submitting={updating}
                    onCancel={() => setEditingId(undefined)}
                    onSubmit={updateNote}
                    value={editingText}
                    onChange={(e) => {
                      setEditingText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && (e.shiftKey || e.metaKey)) {
                        updateNote();
                      }
                    }}
                  />
                ) : (
                  <aside
                    key={c.id}
                    className="bg-border/10 border border-transparent flex flex-col gap-4 p-4 rounded-lg"
                  >
                    <p className="whitespace-pre-wrap">{c.text}</p>
                    <footer className="flex items-center gap-2 h-5 mt-0.5 text-2xs text-primary">
                      <span className="flex-auto">You, {relativeTime}</span>
                      {
                        <>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              setEditingId(c.id);
                              setEditingText(c.text);
                            }}
                          >
                            <Icon icon="edit_note" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              deleteNote(c.id);
                            }}
                          >
                            <Icon icon="delete" />
                          </Button>
                        </>
                      }
                    </footer>
                  </aside>
                )}
              </>
            );
          })}
        </EntitySection>
      )}
      {savedItem && (
        <EntitySection header="Write a Note">
          <CommentArea
            submitting={refreshing}
            value={note}
            onSubmit={submitNote}
            onCancel={() => setEditingId(undefined)}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && (e.shiftKey || e.metaKey)) {
                submitNote();
              }
            }}
          />
          {/* <ReactionsMenu
            handleSelect={(selection) => {
              // TODO: Some formatting here
              setNote((curr) => curr + selection);
            }}
            selected={savedItem.reaction}
          /> */}
        </EntitySection>
      )}
    </>
  );
};

const CommentArea: React.FC<TextareaProps & { submitting: boolean; onSubmit: () => void; onCancel: () => void }> = ({
  submitting,
  onSubmit,
  onCancel,
  ...props
}) => {
  return (
    <div className="relative">
      <Textarea
        disabled={submitting}
        className={cn(
          "relative bg-border/5 border-border/50 h-24 resize-none text-foreground placeholder:text-foreground/50",
          props.className
        )}
        placeholder={submitting ? "Submitting..." : "Anything else to remember for later?"}
        {...props}
      />
      {props.value && (
        <div className="absolute bottom-2 flex gap-2 right-2">
          <Button size="sm" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="sm" onClick={onSubmit} disabled={!props.value || submitting}>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};
