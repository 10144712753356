import Markdown from "react-markdown";

import { Loading } from "@/components/ui/loading";
import { EntityFragment } from "../../generated/graphql";
import { EntitySection } from "./entity";
import { usePollForRecipe } from "./use-poll-for-recipe";

interface Props {
  entity: EntityFragment;
}

export const EntityRecipe: React.FC<Props> = ({ entity }) => {
  const { failed } = usePollForRecipe(entity);

  const markdownMatch = entity.recipeSummary?.match(/^```markdown((.|\n)+)```$/);
  const formattedSummary = markdownMatch ? markdownMatch[1] : entity.recipeSummary;

  return (
    <EntitySection header="Recipe">
      {formattedSummary ? (
        <Markdown>{formattedSummary}</Markdown>
      ) : failed ? (
        <p>Error Loading Recipe</p>
      ) : (
        <Loading label="Summarizing Recipe..." />
      )}
    </EntitySection>
  );
};
