import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: string & { __scalar: "UUID" };
};

export type ConfirmCodeInput = {
  code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type CreateListInput = {
  description?: InputMaybe<Scalars['String']>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  label: Scalars['String'];
  public?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<ListType>;
};

export type CreateRecommendationInput = {
  entityId?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  sentiment: Scalars['Float'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type CreateShareInput = {
  entityId: Scalars['String'];
  fromUser?: InputMaybe<Scalars['String']>;
};

export enum KnownEventName {
  AddedToList = 'AddedToList',
  CommentAdded = 'CommentAdded',
  ItemSaved = 'ItemSaved',
  ListCreated = 'ListCreated',
  SearchSubmitted = 'SearchSubmitted'
}

export enum ListRelationship {
  Collaborator = 'Collaborator',
  Follower = 'Follower',
  Owner = 'Owner',
  PendingCollaborator = 'PendingCollaborator',
  Visitor = 'Visitor'
}

export enum ListType {
  Explore = 'Explore',
  Gift = 'Gift',
  Guide = 'Guide',
  HallOfFame = 'HallOfFame',
  Pile = 'Pile',
  Queue = 'Queue'
}

export type OnboardingQuestionResponse = {
  questionId: Scalars['String'];
  responses: Array<Scalars['String']>;
};

export type RequestCodeInput = {
  phone: Scalars['String'];
};

export type SaveItemInput = {
  entityId: Scalars['String'];
  fromShareId?: InputMaybe<Scalars['String']>;
  fromUsername?: InputMaybe<Scalars['String']>;
};

export enum SearchQueryType {
  Search = 'Search',
  Url = 'URL'
}

export type SubmitOnboardingResponsesInput = {
  responses: Array<OnboardingQuestionResponse>;
};

export type UpdateListInput = {
  description?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  listId: Scalars['String'];
  public?: InputMaybe<Scalars['Boolean']>;
  shouldDelete?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateListRelationshipInput = {
  following: Scalars['Boolean'];
  listId: Scalars['String'];
};

export type UpdateRecommendationInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
  recId: Scalars['String'];
  sentiment?: InputMaybe<Scalars['Float']>;
  shouldDelete?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateSavedItemInput = {
  addSource?: InputMaybe<Scalars['String']>;
  addToList?: InputMaybe<Scalars['String']>;
  initialNote?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  removeFromList?: InputMaybe<Scalars['String']>;
  removeSource?: InputMaybe<Scalars['String']>;
  shouldDelete?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpsertUserNoteInput = {
  entityId?: InputMaybe<Scalars['String']>;
  noteId?: InputMaybe<Scalars['String']>;
  shouldDelete?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type MyInterestsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyInterestsQuery = { __typename?: 'Query', userInterests: Array<{ __typename?: 'UserInterest', id: string, label: string }> };

export type SuggestInterestsQueryVariables = Exact<{
  lastSavedId?: InputMaybe<Scalars['String']>;
}>;


export type SuggestInterestsQuery = { __typename?: 'Query', suggestInterests: Array<{ __typename?: 'UserInterest', id: string, label: string }> };

export type SaveUserInterestMutationVariables = Exact<{
  interestId: Scalars['String'];
}>;


export type SaveUserInterestMutation = { __typename?: 'Mutation', saveUserInterest: { __typename?: 'UserInterest', id: string, label: string } };

export type DeleteUserInterestMutationVariables = Exact<{
  interestId: Scalars['String'];
}>;


export type DeleteUserInterestMutation = { __typename?: 'Mutation', deleteUserInterest: { __typename?: 'UserInterest', id: string, label: string } };

export type UserInterestFragment = { __typename?: 'UserInterest', id: string, label: string };

export type MyListsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyListsQuery = { __typename?: 'Query', myLists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> };

export type MyFollowedListsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyFollowedListsQuery = { __typename?: 'Query', myFollowedLists: Array<{ __typename?: 'List', relationship: ListRelationship, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean, savedItems: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> }> };

export type UserListsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserListsQuery = { __typename?: 'Query', userLists: Array<{ __typename?: 'List', relationship: ListRelationship, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean, savedItems: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> }> };

export type ListDetailsQueryVariables = Exact<{
  listId: Scalars['String'];
}>;


export type ListDetailsQuery = { __typename?: 'Query', listDetails: { __typename?: 'List', relationship: ListRelationship, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean, savedItems: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> } };

export type CreateListMutationVariables = Exact<{
  input: CreateListInput;
}>;


export type CreateListMutation = { __typename?: 'Mutation', createList: { __typename?: 'List', relationship: ListRelationship, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean, savedItems: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> } };

export type UpdateListMutationVariables = Exact<{
  input: UpdateListInput;
}>;


export type UpdateListMutation = { __typename?: 'Mutation', updateList: { __typename?: 'List', relationship: ListRelationship, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean, savedItems: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> } };

export type UpdateListRelationshipMutationVariables = Exact<{
  input: UpdateListRelationshipInput;
}>;


export type UpdateListRelationshipMutation = { __typename?: 'Mutation', updateListRelationship: { __typename?: 'List', relationship: ListRelationship, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean, savedItems: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> } };

export type ListMetadataFragment = { __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean };

export type ListFragment = { __typename?: 'List', relationship: ListRelationship, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean, savedItems: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> };

export type LoadRecommendationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadRecommendationsQuery = { __typename?: 'Query', loadRecommendations: Array<{ __typename?: 'Recommendation', id: string, createdAt: any, updatedAt: any, text: string, sentiment: number, imageUrl?: string | null, entity: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null }, user: { __typename?: 'User', id: string & { __scalar: "UUID" }, username: string, name?: string | null, email?: string | null, phone: string, profilePhoto?: string | null } }> };

export type CreateRecommendationMutationVariables = Exact<{
  input: CreateRecommendationInput;
}>;


export type CreateRecommendationMutation = { __typename?: 'Mutation', createRecommendation: { __typename?: 'Recommendation', id: string, createdAt: any, updatedAt: any, text: string, sentiment: number, imageUrl?: string | null, entity: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null }, user: { __typename?: 'User', id: string & { __scalar: "UUID" }, username: string, name?: string | null, email?: string | null, phone: string, profilePhoto?: string | null } } };

export type UpdateRecommendationMutationVariables = Exact<{
  input: UpdateRecommendationInput;
}>;


export type UpdateRecommendationMutation = { __typename?: 'Mutation', updateRecommendation: { __typename?: 'Recommendation', id: string, createdAt: any, updatedAt: any, text: string, sentiment: number, imageUrl?: string | null, entity: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null }, user: { __typename?: 'User', id: string & { __scalar: "UUID" }, username: string, name?: string | null, email?: string | null, phone: string, profilePhoto?: string | null } } };

export type RecommendationFragment = { __typename?: 'Recommendation', id: string, createdAt: any, updatedAt: any, text: string, sentiment: number, imageUrl?: string | null, entity: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null }, user: { __typename?: 'User', id: string & { __scalar: "UUID" }, username: string, name?: string | null, email?: string | null, phone: string, profilePhoto?: string | null } };

export type MySavedItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type MySavedItemsQuery = { __typename?: 'Query', savedItemsForUser: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> };

export type SavedItemsForUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type SavedItemsForUserQuery = { __typename?: 'Query', savedItemsForUser: Array<{ __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> }> };

export type SaveItemMutationVariables = Exact<{
  input: SaveItemInput;
}>;


export type SaveItemMutation = { __typename?: 'Mutation', saveItem: { __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> } };

export type UpdateSavedItemMutationVariables = Exact<{
  input: UpdateSavedItemInput;
}>;


export type UpdateSavedItemMutation = { __typename?: 'Mutation', updateSavedItem: { __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> } };

export type SavedItemFragment = { __typename?: 'SavedItem', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, reaction: string, public: boolean, userId: string, entity?: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } | null, sources: Array<{ __typename?: 'Source', id: string, label: string, remoteUserId?: string | null }>, lists: Array<{ __typename?: 'List', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, type: ListType, label: string, description?: string | null, userId: string, username: string, public: boolean }> };

export type SearchV2QueryVariables = Exact<{
  term: Scalars['String'];
}>;


export type SearchV2Query = { __typename?: 'Query', searchV2: { __typename?: 'SearchResponseV2', query: string, type: SearchQueryType, myResults: Array<{ __typename?: 'SearchResultV2', title: string, type: string, imageUrl?: string | null, description?: string | null, savorUrl?: string | null, entityId?: string | null }>, networkResults: Array<{ __typename?: 'SearchResultV2', title: string, type: string, imageUrl?: string | null, description?: string | null, savorUrl?: string | null, entityId?: string | null }>, internetResults: Array<{ __typename?: 'SearchResultV2', title: string, type: string, imageUrl?: string | null, description?: string | null, savorUrl?: string | null, entityId?: string | null }> } };

export type SearchResponseV2Fragment = { __typename?: 'SearchResponseV2', query: string, type: SearchQueryType, myResults: Array<{ __typename?: 'SearchResultV2', title: string, type: string, imageUrl?: string | null, description?: string | null, savorUrl?: string | null, entityId?: string | null }>, networkResults: Array<{ __typename?: 'SearchResultV2', title: string, type: string, imageUrl?: string | null, description?: string | null, savorUrl?: string | null, entityId?: string | null }>, internetResults: Array<{ __typename?: 'SearchResultV2', title: string, type: string, imageUrl?: string | null, description?: string | null, savorUrl?: string | null, entityId?: string | null }> };

export type SearchResultV2Fragment = { __typename?: 'SearchResultV2', title: string, type: string, imageUrl?: string | null, description?: string | null, savorUrl?: string | null, entityId?: string | null };

export type EntityFragment = { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> };

export type UserFragment = { __typename?: 'User', id: string & { __scalar: "UUID" }, username: string, name?: string | null, email?: string | null, phone: string, profilePhoto?: string | null };

export type ConfirmCodeMutationVariables = Exact<{
  input: ConfirmCodeInput;
}>;


export type ConfirmCodeMutation = { __typename?: 'Mutation', confirmCode: { __typename?: 'ConfirmedUser', id: string & { __scalar: "UUID" }, accessToken: string } };

export type CreateShareMutationVariables = Exact<{
  input: CreateShareInput;
}>;


export type CreateShareMutation = { __typename?: 'Mutation', createShare: { __typename?: 'Share', id: string } };

export type GenerateThumbnailMutationVariables = Exact<{
  entityId: Scalars['String'];
}>;


export type GenerateThumbnailMutation = { __typename?: 'Mutation', generateThumbnail: { __typename?: 'CanonicalEntity', id: string, imageUrl?: string | null, thumbnailUrl?: string | null } };

export type RequestCodeMutationVariables = Exact<{
  input: RequestCodeInput;
}>;


export type RequestCodeMutation = { __typename?: 'Mutation', requestCode: { __typename?: 'PartialUserObject', userId: string, name?: string | null } };

export type SubmitOnboardingMutationVariables = Exact<{
  input: SubmitOnboardingResponsesInput;
}>;


export type SubmitOnboardingMutation = { __typename?: 'Mutation', submitOnboardingResponses: { __typename: 'OnboardingState' } };

export type UpdateProfilePhotoMutationVariables = Exact<{
  dataUrl: Scalars['String'];
}>;


export type UpdateProfilePhotoMutation = { __typename?: 'Mutation', updateProfilePhoto: { __typename?: 'User', id: string & { __scalar: "UUID" }, profilePhoto?: string | null } };

export type UpsertNoteMutationVariables = Exact<{
  input: UpsertUserNoteInput;
}>;


export type UpsertNoteMutation = { __typename?: 'Mutation', upsertNote: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } };

export type AllEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllEntitiesQuery = { __typename?: 'Query', allEntities: Array<{ __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> }> };

export type EntityDetailsQueryVariables = Exact<{
  entityId: Scalars['String'];
}>;


export type EntityDetailsQuery = { __typename?: 'Query', entityDetails: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> } };

export type GetActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActivityQuery = { __typename?: 'Query', getActivity: Array<{ __typename: 'ActivityMessage', eventName: KnownEventName, timestamp: any, username: string, entityTitle?: string | null, entityId?: string | null, listId?: string | null, listLabel?: string | null, sourceUsername?: string | null, comment?: string | null, imageUrl?: string | null, private: boolean }> };

export type ActivityMessageFragment = { __typename: 'ActivityMessage', eventName: KnownEventName, timestamp: any, username: string, entityTitle?: string | null, entityId?: string | null, listId?: string | null, listLabel?: string | null, sourceUsername?: string | null, comment?: string | null, imageUrl?: string | null, private: boolean };

export type GetShareQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetShareQuery = { __typename?: 'Query', getShare: { __typename?: 'Share', id: string, note?: string | null, entityId: string, sourceUserId: string, entity: { __typename?: 'CanonicalEntity', id: string, title: string, type: string, imageUrl?: string | null, thumbnailUrl?: string | null, description: string, recipeUrl?: string | null, recipeSummary?: string | null, links: Array<{ __typename?: 'EntityLink', url: string, label?: string | null, affiliate?: boolean | null }>, notes: Array<{ __typename?: 'UserNote', id: string, createdAt: any, text: string }> }, sourceUser: { __typename?: 'User', id: string & { __scalar: "UUID" }, phone: string, username: string, email?: string | null, name?: string | null } } };

export type LoadUserQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type LoadUserQuery = { __typename?: 'Query', loadUser: { __typename?: 'User', id: string & { __scalar: "UUID" }, username: string, name?: string | null, email?: string | null, phone: string, profilePhoto?: string | null } };

export type MyAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAccountQuery = { __typename?: 'Query', myAccount: { __typename?: 'User', id: string & { __scalar: "UUID" }, username: string, name?: string | null, email?: string | null, phone: string, profilePhoto?: string | null } };

export type MySourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type MySourcesQuery = { __typename?: 'Query', mySources: Array<{ __typename?: 'Source', id: string, label: string }> };

export type OnboardingStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingStatusQuery = { __typename?: 'Query', onboardingStatus: { __typename?: 'OnboardingState', hasCompleted: boolean, introText: string, questionsRemaining: Array<{ __typename?: 'OnboardingQuestion', questionId: string, text: string, subheader?: string | null, options: Array<string>, required: boolean, multiSelect: boolean }> } };

export const UserInterestFragmentDoc = gql`
    fragment UserInterest on UserInterest {
  id
  label
}
    `;
export const ListMetadataFragmentDoc = gql`
    fragment ListMetadata on List {
  id
  createdAt
  updatedAt
  deletedAt
  type
  label
  description
  userId
  username
  public
}
    `;
export const EntityFragmentDoc = gql`
    fragment Entity on CanonicalEntity {
  id
  title
  type
  imageUrl
  thumbnailUrl
  description
  links {
    url
    label
    affiliate
  }
  notes {
    id
    createdAt
    text
  }
  recipeUrl
  recipeSummary
}
    `;
export const SavedItemFragmentDoc = gql`
    fragment SavedItem on SavedItem {
  id
  createdAt
  updatedAt
  deletedAt
  title
  reaction
  public
  userId
  entity {
    ...Entity
  }
  sources {
    id
    label
    remoteUserId
  }
  lists {
    ...ListMetadata
  }
}
    `;
export const ListFragmentDoc = gql`
    fragment List on List {
  ...ListMetadata
  relationship
  savedItems {
    ...SavedItem
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  username
  name
  email
  phone
  profilePhoto
}
    `;
export const RecommendationFragmentDoc = gql`
    fragment Recommendation on Recommendation {
  id
  createdAt
  updatedAt
  text
  sentiment
  imageUrl
  entity {
    id
    title
    type
    imageUrl
    thumbnailUrl
  }
  user {
    ...User
  }
}
    `;
export const SearchResultV2FragmentDoc = gql`
    fragment SearchResultV2 on SearchResultV2 {
  title
  type
  imageUrl
  description
  savorUrl
  entityId
}
    `;
export const SearchResponseV2FragmentDoc = gql`
    fragment SearchResponseV2 on SearchResponseV2 {
  query
  type
  myResults {
    ...SearchResultV2
  }
  networkResults {
    ...SearchResultV2
  }
  internetResults {
    ...SearchResultV2
  }
}
    `;
export const ActivityMessageFragmentDoc = gql`
    fragment ActivityMessage on ActivityMessage {
  __typename
  eventName
  timestamp
  username
  entityTitle
  entityId
  listId
  listLabel
  sourceUsername
  comment
  imageUrl
  private
}
    `;
export const MyInterestsDocument = gql`
    query MyInterests {
  userInterests {
    ...UserInterest
  }
}
    ${UserInterestFragmentDoc}`;

/**
 * __useMyInterestsQuery__
 *
 * To run a query within a React component, call `useMyInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyInterestsQuery(baseOptions?: Apollo.QueryHookOptions<MyInterestsQuery, MyInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyInterestsQuery, MyInterestsQueryVariables>(MyInterestsDocument, options);
      }
export function useMyInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyInterestsQuery, MyInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyInterestsQuery, MyInterestsQueryVariables>(MyInterestsDocument, options);
        }
export type MyInterestsQueryHookResult = ReturnType<typeof useMyInterestsQuery>;
export type MyInterestsLazyQueryHookResult = ReturnType<typeof useMyInterestsLazyQuery>;
export type MyInterestsQueryResult = Apollo.QueryResult<MyInterestsQuery, MyInterestsQueryVariables>;
export const SuggestInterestsDocument = gql`
    query SuggestInterests($lastSavedId: String) {
  suggestInterests(lastSavedId: $lastSavedId) {
    ...UserInterest
  }
}
    ${UserInterestFragmentDoc}`;

/**
 * __useSuggestInterestsQuery__
 *
 * To run a query within a React component, call `useSuggestInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestInterestsQuery({
 *   variables: {
 *      lastSavedId: // value for 'lastSavedId'
 *   },
 * });
 */
export function useSuggestInterestsQuery(baseOptions?: Apollo.QueryHookOptions<SuggestInterestsQuery, SuggestInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestInterestsQuery, SuggestInterestsQueryVariables>(SuggestInterestsDocument, options);
      }
export function useSuggestInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestInterestsQuery, SuggestInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestInterestsQuery, SuggestInterestsQueryVariables>(SuggestInterestsDocument, options);
        }
export type SuggestInterestsQueryHookResult = ReturnType<typeof useSuggestInterestsQuery>;
export type SuggestInterestsLazyQueryHookResult = ReturnType<typeof useSuggestInterestsLazyQuery>;
export type SuggestInterestsQueryResult = Apollo.QueryResult<SuggestInterestsQuery, SuggestInterestsQueryVariables>;
export const SaveUserInterestDocument = gql`
    mutation saveUserInterest($interestId: String!) {
  saveUserInterest(interestId: $interestId) {
    ...UserInterest
  }
}
    ${UserInterestFragmentDoc}`;
export type SaveUserInterestMutationFn = Apollo.MutationFunction<SaveUserInterestMutation, SaveUserInterestMutationVariables>;

/**
 * __useSaveUserInterestMutation__
 *
 * To run a mutation, you first call `useSaveUserInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserInterestMutation, { data, loading, error }] = useSaveUserInterestMutation({
 *   variables: {
 *      interestId: // value for 'interestId'
 *   },
 * });
 */
export function useSaveUserInterestMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserInterestMutation, SaveUserInterestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserInterestMutation, SaveUserInterestMutationVariables>(SaveUserInterestDocument, options);
      }
export type SaveUserInterestMutationHookResult = ReturnType<typeof useSaveUserInterestMutation>;
export type SaveUserInterestMutationResult = Apollo.MutationResult<SaveUserInterestMutation>;
export type SaveUserInterestMutationOptions = Apollo.BaseMutationOptions<SaveUserInterestMutation, SaveUserInterestMutationVariables>;
export const DeleteUserInterestDocument = gql`
    mutation deleteUserInterest($interestId: String!) {
  deleteUserInterest(interestId: $interestId) {
    ...UserInterest
  }
}
    ${UserInterestFragmentDoc}`;
export type DeleteUserInterestMutationFn = Apollo.MutationFunction<DeleteUserInterestMutation, DeleteUserInterestMutationVariables>;

/**
 * __useDeleteUserInterestMutation__
 *
 * To run a mutation, you first call `useDeleteUserInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserInterestMutation, { data, loading, error }] = useDeleteUserInterestMutation({
 *   variables: {
 *      interestId: // value for 'interestId'
 *   },
 * });
 */
export function useDeleteUserInterestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserInterestMutation, DeleteUserInterestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserInterestMutation, DeleteUserInterestMutationVariables>(DeleteUserInterestDocument, options);
      }
export type DeleteUserInterestMutationHookResult = ReturnType<typeof useDeleteUserInterestMutation>;
export type DeleteUserInterestMutationResult = Apollo.MutationResult<DeleteUserInterestMutation>;
export type DeleteUserInterestMutationOptions = Apollo.BaseMutationOptions<DeleteUserInterestMutation, DeleteUserInterestMutationVariables>;
export const MyListsDocument = gql`
    query MyLists {
  myLists {
    ...ListMetadata
  }
}
    ${ListMetadataFragmentDoc}`;

/**
 * __useMyListsQuery__
 *
 * To run a query within a React component, call `useMyListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyListsQuery(baseOptions?: Apollo.QueryHookOptions<MyListsQuery, MyListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyListsQuery, MyListsQueryVariables>(MyListsDocument, options);
      }
export function useMyListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyListsQuery, MyListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyListsQuery, MyListsQueryVariables>(MyListsDocument, options);
        }
export type MyListsQueryHookResult = ReturnType<typeof useMyListsQuery>;
export type MyListsLazyQueryHookResult = ReturnType<typeof useMyListsLazyQuery>;
export type MyListsQueryResult = Apollo.QueryResult<MyListsQuery, MyListsQueryVariables>;
export const MyFollowedListsDocument = gql`
    query MyFollowedLists {
  myFollowedLists {
    ...List
  }
}
    ${ListFragmentDoc}
${ListMetadataFragmentDoc}
${SavedItemFragmentDoc}
${EntityFragmentDoc}`;

/**
 * __useMyFollowedListsQuery__
 *
 * To run a query within a React component, call `useMyFollowedListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFollowedListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFollowedListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFollowedListsQuery(baseOptions?: Apollo.QueryHookOptions<MyFollowedListsQuery, MyFollowedListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyFollowedListsQuery, MyFollowedListsQueryVariables>(MyFollowedListsDocument, options);
      }
export function useMyFollowedListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyFollowedListsQuery, MyFollowedListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyFollowedListsQuery, MyFollowedListsQueryVariables>(MyFollowedListsDocument, options);
        }
export type MyFollowedListsQueryHookResult = ReturnType<typeof useMyFollowedListsQuery>;
export type MyFollowedListsLazyQueryHookResult = ReturnType<typeof useMyFollowedListsLazyQuery>;
export type MyFollowedListsQueryResult = Apollo.QueryResult<MyFollowedListsQuery, MyFollowedListsQueryVariables>;
export const UserListsDocument = gql`
    query UserLists($userId: String!) {
  userLists(userId: $userId) {
    ...List
  }
}
    ${ListFragmentDoc}
${ListMetadataFragmentDoc}
${SavedItemFragmentDoc}
${EntityFragmentDoc}`;

/**
 * __useUserListsQuery__
 *
 * To run a query within a React component, call `useUserListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserListsQuery(baseOptions: Apollo.QueryHookOptions<UserListsQuery, UserListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserListsQuery, UserListsQueryVariables>(UserListsDocument, options);
      }
export function useUserListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListsQuery, UserListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserListsQuery, UserListsQueryVariables>(UserListsDocument, options);
        }
export type UserListsQueryHookResult = ReturnType<typeof useUserListsQuery>;
export type UserListsLazyQueryHookResult = ReturnType<typeof useUserListsLazyQuery>;
export type UserListsQueryResult = Apollo.QueryResult<UserListsQuery, UserListsQueryVariables>;
export const ListDetailsDocument = gql`
    query ListDetails($listId: String!) {
  listDetails(listId: $listId) {
    ...List
  }
}
    ${ListFragmentDoc}
${ListMetadataFragmentDoc}
${SavedItemFragmentDoc}
${EntityFragmentDoc}`;

/**
 * __useListDetailsQuery__
 *
 * To run a query within a React component, call `useListDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDetailsQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useListDetailsQuery(baseOptions: Apollo.QueryHookOptions<ListDetailsQuery, ListDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDetailsQuery, ListDetailsQueryVariables>(ListDetailsDocument, options);
      }
export function useListDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDetailsQuery, ListDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDetailsQuery, ListDetailsQueryVariables>(ListDetailsDocument, options);
        }
export type ListDetailsQueryHookResult = ReturnType<typeof useListDetailsQuery>;
export type ListDetailsLazyQueryHookResult = ReturnType<typeof useListDetailsLazyQuery>;
export type ListDetailsQueryResult = Apollo.QueryResult<ListDetailsQuery, ListDetailsQueryVariables>;
export const CreateListDocument = gql`
    mutation CreateList($input: CreateListInput!) {
  createList(input: $input) {
    ...List
  }
}
    ${ListFragmentDoc}
${ListMetadataFragmentDoc}
${SavedItemFragmentDoc}
${EntityFragmentDoc}`;
export type CreateListMutationFn = Apollo.MutationFunction<CreateListMutation, CreateListMutationVariables>;

/**
 * __useCreateListMutation__
 *
 * To run a mutation, you first call `useCreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListMutation, { data, loading, error }] = useCreateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListMutation(baseOptions?: Apollo.MutationHookOptions<CreateListMutation, CreateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateListMutation, CreateListMutationVariables>(CreateListDocument, options);
      }
export type CreateListMutationHookResult = ReturnType<typeof useCreateListMutation>;
export type CreateListMutationResult = Apollo.MutationResult<CreateListMutation>;
export type CreateListMutationOptions = Apollo.BaseMutationOptions<CreateListMutation, CreateListMutationVariables>;
export const UpdateListDocument = gql`
    mutation UpdateList($input: UpdateListInput!) {
  updateList(input: $input) {
    ...List
  }
}
    ${ListFragmentDoc}
${ListMetadataFragmentDoc}
${SavedItemFragmentDoc}
${EntityFragmentDoc}`;
export type UpdateListMutationFn = Apollo.MutationFunction<UpdateListMutation, UpdateListMutationVariables>;

/**
 * __useUpdateListMutation__
 *
 * To run a mutation, you first call `useUpdateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListMutation, { data, loading, error }] = useUpdateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListMutation, UpdateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateListMutation, UpdateListMutationVariables>(UpdateListDocument, options);
      }
export type UpdateListMutationHookResult = ReturnType<typeof useUpdateListMutation>;
export type UpdateListMutationResult = Apollo.MutationResult<UpdateListMutation>;
export type UpdateListMutationOptions = Apollo.BaseMutationOptions<UpdateListMutation, UpdateListMutationVariables>;
export const UpdateListRelationshipDocument = gql`
    mutation UpdateListRelationship($input: UpdateListRelationshipInput!) {
  updateListRelationship(input: $input) {
    ...List
  }
}
    ${ListFragmentDoc}
${ListMetadataFragmentDoc}
${SavedItemFragmentDoc}
${EntityFragmentDoc}`;
export type UpdateListRelationshipMutationFn = Apollo.MutationFunction<UpdateListRelationshipMutation, UpdateListRelationshipMutationVariables>;

/**
 * __useUpdateListRelationshipMutation__
 *
 * To run a mutation, you first call `useUpdateListRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListRelationshipMutation, { data, loading, error }] = useUpdateListRelationshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListRelationshipMutation, UpdateListRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateListRelationshipMutation, UpdateListRelationshipMutationVariables>(UpdateListRelationshipDocument, options);
      }
export type UpdateListRelationshipMutationHookResult = ReturnType<typeof useUpdateListRelationshipMutation>;
export type UpdateListRelationshipMutationResult = Apollo.MutationResult<UpdateListRelationshipMutation>;
export type UpdateListRelationshipMutationOptions = Apollo.BaseMutationOptions<UpdateListRelationshipMutation, UpdateListRelationshipMutationVariables>;
export const LoadRecommendationsDocument = gql`
    query LoadRecommendations {
  loadRecommendations(input: {}) {
    ...Recommendation
  }
}
    ${RecommendationFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useLoadRecommendationsQuery__
 *
 * To run a query within a React component, call `useLoadRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRecommendationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadRecommendationsQuery(baseOptions?: Apollo.QueryHookOptions<LoadRecommendationsQuery, LoadRecommendationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadRecommendationsQuery, LoadRecommendationsQueryVariables>(LoadRecommendationsDocument, options);
      }
export function useLoadRecommendationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadRecommendationsQuery, LoadRecommendationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadRecommendationsQuery, LoadRecommendationsQueryVariables>(LoadRecommendationsDocument, options);
        }
export type LoadRecommendationsQueryHookResult = ReturnType<typeof useLoadRecommendationsQuery>;
export type LoadRecommendationsLazyQueryHookResult = ReturnType<typeof useLoadRecommendationsLazyQuery>;
export type LoadRecommendationsQueryResult = Apollo.QueryResult<LoadRecommendationsQuery, LoadRecommendationsQueryVariables>;
export const CreateRecommendationDocument = gql`
    mutation CreateRecommendation($input: CreateRecommendationInput!) {
  createRecommendation(input: $input) {
    ...Recommendation
  }
}
    ${RecommendationFragmentDoc}
${UserFragmentDoc}`;
export type CreateRecommendationMutationFn = Apollo.MutationFunction<CreateRecommendationMutation, CreateRecommendationMutationVariables>;

/**
 * __useCreateRecommendationMutation__
 *
 * To run a mutation, you first call `useCreateRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecommendationMutation, { data, loading, error }] = useCreateRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecommendationMutation, CreateRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecommendationMutation, CreateRecommendationMutationVariables>(CreateRecommendationDocument, options);
      }
export type CreateRecommendationMutationHookResult = ReturnType<typeof useCreateRecommendationMutation>;
export type CreateRecommendationMutationResult = Apollo.MutationResult<CreateRecommendationMutation>;
export type CreateRecommendationMutationOptions = Apollo.BaseMutationOptions<CreateRecommendationMutation, CreateRecommendationMutationVariables>;
export const UpdateRecommendationDocument = gql`
    mutation UpdateRecommendation($input: UpdateRecommendationInput!) {
  updateRecommendation(input: $input) {
    ...Recommendation
  }
}
    ${RecommendationFragmentDoc}
${UserFragmentDoc}`;
export type UpdateRecommendationMutationFn = Apollo.MutationFunction<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>;

/**
 * __useUpdateRecommendationMutation__
 *
 * To run a mutation, you first call `useUpdateRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecommendationMutation, { data, loading, error }] = useUpdateRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>(UpdateRecommendationDocument, options);
      }
export type UpdateRecommendationMutationHookResult = ReturnType<typeof useUpdateRecommendationMutation>;
export type UpdateRecommendationMutationResult = Apollo.MutationResult<UpdateRecommendationMutation>;
export type UpdateRecommendationMutationOptions = Apollo.BaseMutationOptions<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>;
export const MySavedItemsDocument = gql`
    query MySavedItems {
  savedItemsForUser {
    ...SavedItem
  }
}
    ${SavedItemFragmentDoc}
${EntityFragmentDoc}
${ListMetadataFragmentDoc}`;

/**
 * __useMySavedItemsQuery__
 *
 * To run a query within a React component, call `useMySavedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySavedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySavedItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySavedItemsQuery(baseOptions?: Apollo.QueryHookOptions<MySavedItemsQuery, MySavedItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MySavedItemsQuery, MySavedItemsQueryVariables>(MySavedItemsDocument, options);
      }
export function useMySavedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MySavedItemsQuery, MySavedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MySavedItemsQuery, MySavedItemsQueryVariables>(MySavedItemsDocument, options);
        }
export type MySavedItemsQueryHookResult = ReturnType<typeof useMySavedItemsQuery>;
export type MySavedItemsLazyQueryHookResult = ReturnType<typeof useMySavedItemsLazyQuery>;
export type MySavedItemsQueryResult = Apollo.QueryResult<MySavedItemsQuery, MySavedItemsQueryVariables>;
export const SavedItemsForUserDocument = gql`
    query SavedItemsForUser($userId: String!) {
  savedItemsForUser(userId: $userId) {
    ...SavedItem
  }
}
    ${SavedItemFragmentDoc}
${EntityFragmentDoc}
${ListMetadataFragmentDoc}`;

/**
 * __useSavedItemsForUserQuery__
 *
 * To run a query within a React component, call `useSavedItemsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedItemsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedItemsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSavedItemsForUserQuery(baseOptions: Apollo.QueryHookOptions<SavedItemsForUserQuery, SavedItemsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SavedItemsForUserQuery, SavedItemsForUserQueryVariables>(SavedItemsForUserDocument, options);
      }
export function useSavedItemsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedItemsForUserQuery, SavedItemsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SavedItemsForUserQuery, SavedItemsForUserQueryVariables>(SavedItemsForUserDocument, options);
        }
export type SavedItemsForUserQueryHookResult = ReturnType<typeof useSavedItemsForUserQuery>;
export type SavedItemsForUserLazyQueryHookResult = ReturnType<typeof useSavedItemsForUserLazyQuery>;
export type SavedItemsForUserQueryResult = Apollo.QueryResult<SavedItemsForUserQuery, SavedItemsForUserQueryVariables>;
export const SaveItemDocument = gql`
    mutation SaveItem($input: SaveItemInput!) {
  saveItem(input: $input) {
    ...SavedItem
  }
}
    ${SavedItemFragmentDoc}
${EntityFragmentDoc}
${ListMetadataFragmentDoc}`;
export type SaveItemMutationFn = Apollo.MutationFunction<SaveItemMutation, SaveItemMutationVariables>;

/**
 * __useSaveItemMutation__
 *
 * To run a mutation, you first call `useSaveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveItemMutation, { data, loading, error }] = useSaveItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveItemMutation(baseOptions?: Apollo.MutationHookOptions<SaveItemMutation, SaveItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveItemMutation, SaveItemMutationVariables>(SaveItemDocument, options);
      }
export type SaveItemMutationHookResult = ReturnType<typeof useSaveItemMutation>;
export type SaveItemMutationResult = Apollo.MutationResult<SaveItemMutation>;
export type SaveItemMutationOptions = Apollo.BaseMutationOptions<SaveItemMutation, SaveItemMutationVariables>;
export const UpdateSavedItemDocument = gql`
    mutation UpdateSavedItem($input: UpdateSavedItemInput!) {
  updateSavedItem(input: $input) {
    ...SavedItem
  }
}
    ${SavedItemFragmentDoc}
${EntityFragmentDoc}
${ListMetadataFragmentDoc}`;
export type UpdateSavedItemMutationFn = Apollo.MutationFunction<UpdateSavedItemMutation, UpdateSavedItemMutationVariables>;

/**
 * __useUpdateSavedItemMutation__
 *
 * To run a mutation, you first call `useUpdateSavedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedItemMutation, { data, loading, error }] = useUpdateSavedItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSavedItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSavedItemMutation, UpdateSavedItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSavedItemMutation, UpdateSavedItemMutationVariables>(UpdateSavedItemDocument, options);
      }
export type UpdateSavedItemMutationHookResult = ReturnType<typeof useUpdateSavedItemMutation>;
export type UpdateSavedItemMutationResult = Apollo.MutationResult<UpdateSavedItemMutation>;
export type UpdateSavedItemMutationOptions = Apollo.BaseMutationOptions<UpdateSavedItemMutation, UpdateSavedItemMutationVariables>;
export const SearchV2Document = gql`
    query SearchV2($term: String!) {
  searchV2(term: $term) {
    ...SearchResponseV2
  }
}
    ${SearchResponseV2FragmentDoc}
${SearchResultV2FragmentDoc}`;

/**
 * __useSearchV2Query__
 *
 * To run a query within a React component, call `useSearchV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchV2Query({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchV2Query(baseOptions: Apollo.QueryHookOptions<SearchV2Query, SearchV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchV2Query, SearchV2QueryVariables>(SearchV2Document, options);
      }
export function useSearchV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchV2Query, SearchV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchV2Query, SearchV2QueryVariables>(SearchV2Document, options);
        }
export type SearchV2QueryHookResult = ReturnType<typeof useSearchV2Query>;
export type SearchV2LazyQueryHookResult = ReturnType<typeof useSearchV2LazyQuery>;
export type SearchV2QueryResult = Apollo.QueryResult<SearchV2Query, SearchV2QueryVariables>;
export const ConfirmCodeDocument = gql`
    mutation ConfirmCode($input: ConfirmCodeInput!) {
  confirmCode(input: $input) {
    id
    accessToken
  }
}
    `;
export type ConfirmCodeMutationFn = Apollo.MutationFunction<ConfirmCodeMutation, ConfirmCodeMutationVariables>;

/**
 * __useConfirmCodeMutation__
 *
 * To run a mutation, you first call `useConfirmCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCodeMutation, { data, loading, error }] = useConfirmCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmCodeMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmCodeMutation, ConfirmCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmCodeMutation, ConfirmCodeMutationVariables>(ConfirmCodeDocument, options);
      }
export type ConfirmCodeMutationHookResult = ReturnType<typeof useConfirmCodeMutation>;
export type ConfirmCodeMutationResult = Apollo.MutationResult<ConfirmCodeMutation>;
export type ConfirmCodeMutationOptions = Apollo.BaseMutationOptions<ConfirmCodeMutation, ConfirmCodeMutationVariables>;
export const CreateShareDocument = gql`
    mutation CreateShare($input: CreateShareInput!) {
  createShare(input: $input) {
    id
  }
}
    `;
export type CreateShareMutationFn = Apollo.MutationFunction<CreateShareMutation, CreateShareMutationVariables>;

/**
 * __useCreateShareMutation__
 *
 * To run a mutation, you first call `useCreateShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShareMutation, { data, loading, error }] = useCreateShareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShareMutation(baseOptions?: Apollo.MutationHookOptions<CreateShareMutation, CreateShareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShareMutation, CreateShareMutationVariables>(CreateShareDocument, options);
      }
export type CreateShareMutationHookResult = ReturnType<typeof useCreateShareMutation>;
export type CreateShareMutationResult = Apollo.MutationResult<CreateShareMutation>;
export type CreateShareMutationOptions = Apollo.BaseMutationOptions<CreateShareMutation, CreateShareMutationVariables>;
export const GenerateThumbnailDocument = gql`
    mutation GenerateThumbnail($entityId: String!) {
  generateThumbnail(entityId: $entityId) {
    id
    imageUrl
    thumbnailUrl
  }
}
    `;
export type GenerateThumbnailMutationFn = Apollo.MutationFunction<GenerateThumbnailMutation, GenerateThumbnailMutationVariables>;

/**
 * __useGenerateThumbnailMutation__
 *
 * To run a mutation, you first call `useGenerateThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateThumbnailMutation, { data, loading, error }] = useGenerateThumbnailMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGenerateThumbnailMutation(baseOptions?: Apollo.MutationHookOptions<GenerateThumbnailMutation, GenerateThumbnailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateThumbnailMutation, GenerateThumbnailMutationVariables>(GenerateThumbnailDocument, options);
      }
export type GenerateThumbnailMutationHookResult = ReturnType<typeof useGenerateThumbnailMutation>;
export type GenerateThumbnailMutationResult = Apollo.MutationResult<GenerateThumbnailMutation>;
export type GenerateThumbnailMutationOptions = Apollo.BaseMutationOptions<GenerateThumbnailMutation, GenerateThumbnailMutationVariables>;
export const RequestCodeDocument = gql`
    mutation RequestCode($input: RequestCodeInput!) {
  requestCode(input: $input) {
    userId
    name
  }
}
    `;
export type RequestCodeMutationFn = Apollo.MutationFunction<RequestCodeMutation, RequestCodeMutationVariables>;

/**
 * __useRequestCodeMutation__
 *
 * To run a mutation, you first call `useRequestCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCodeMutation, { data, loading, error }] = useRequestCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestCodeMutation(baseOptions?: Apollo.MutationHookOptions<RequestCodeMutation, RequestCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestCodeMutation, RequestCodeMutationVariables>(RequestCodeDocument, options);
      }
export type RequestCodeMutationHookResult = ReturnType<typeof useRequestCodeMutation>;
export type RequestCodeMutationResult = Apollo.MutationResult<RequestCodeMutation>;
export type RequestCodeMutationOptions = Apollo.BaseMutationOptions<RequestCodeMutation, RequestCodeMutationVariables>;
export const SubmitOnboardingDocument = gql`
    mutation SubmitOnboarding($input: SubmitOnboardingResponsesInput!) {
  submitOnboardingResponses(input: $input) {
    __typename
  }
}
    `;
export type SubmitOnboardingMutationFn = Apollo.MutationFunction<SubmitOnboardingMutation, SubmitOnboardingMutationVariables>;

/**
 * __useSubmitOnboardingMutation__
 *
 * To run a mutation, you first call `useSubmitOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOnboardingMutation, { data, loading, error }] = useSubmitOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<SubmitOnboardingMutation, SubmitOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitOnboardingMutation, SubmitOnboardingMutationVariables>(SubmitOnboardingDocument, options);
      }
export type SubmitOnboardingMutationHookResult = ReturnType<typeof useSubmitOnboardingMutation>;
export type SubmitOnboardingMutationResult = Apollo.MutationResult<SubmitOnboardingMutation>;
export type SubmitOnboardingMutationOptions = Apollo.BaseMutationOptions<SubmitOnboardingMutation, SubmitOnboardingMutationVariables>;
export const UpdateProfilePhotoDocument = gql`
    mutation UpdateProfilePhoto($dataUrl: String!) {
  updateProfilePhoto(dataUrl: $dataUrl) {
    id
    profilePhoto
  }
}
    `;
export type UpdateProfilePhotoMutationFn = Apollo.MutationFunction<UpdateProfilePhotoMutation, UpdateProfilePhotoMutationVariables>;

/**
 * __useUpdateProfilePhotoMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePhotoMutation, { data, loading, error }] = useUpdateProfilePhotoMutation({
 *   variables: {
 *      dataUrl: // value for 'dataUrl'
 *   },
 * });
 */
export function useUpdateProfilePhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePhotoMutation, UpdateProfilePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfilePhotoMutation, UpdateProfilePhotoMutationVariables>(UpdateProfilePhotoDocument, options);
      }
export type UpdateProfilePhotoMutationHookResult = ReturnType<typeof useUpdateProfilePhotoMutation>;
export type UpdateProfilePhotoMutationResult = Apollo.MutationResult<UpdateProfilePhotoMutation>;
export type UpdateProfilePhotoMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePhotoMutation, UpdateProfilePhotoMutationVariables>;
export const UpsertNoteDocument = gql`
    mutation UpsertNote($input: UpsertUserNoteInput!) {
  upsertNote(input: $input) {
    ...Entity
  }
}
    ${EntityFragmentDoc}`;
export type UpsertNoteMutationFn = Apollo.MutationFunction<UpsertNoteMutation, UpsertNoteMutationVariables>;

/**
 * __useUpsertNoteMutation__
 *
 * To run a mutation, you first call `useUpsertNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNoteMutation, { data, loading, error }] = useUpsertNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpsertNoteMutation, UpsertNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertNoteMutation, UpsertNoteMutationVariables>(UpsertNoteDocument, options);
      }
export type UpsertNoteMutationHookResult = ReturnType<typeof useUpsertNoteMutation>;
export type UpsertNoteMutationResult = Apollo.MutationResult<UpsertNoteMutation>;
export type UpsertNoteMutationOptions = Apollo.BaseMutationOptions<UpsertNoteMutation, UpsertNoteMutationVariables>;
export const AllEntitiesDocument = gql`
    query AllEntities {
  allEntities {
    ...Entity
  }
}
    ${EntityFragmentDoc}`;

/**
 * __useAllEntitiesQuery__
 *
 * To run a query within a React component, call `useAllEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<AllEntitiesQuery, AllEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllEntitiesQuery, AllEntitiesQueryVariables>(AllEntitiesDocument, options);
      }
export function useAllEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllEntitiesQuery, AllEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllEntitiesQuery, AllEntitiesQueryVariables>(AllEntitiesDocument, options);
        }
export type AllEntitiesQueryHookResult = ReturnType<typeof useAllEntitiesQuery>;
export type AllEntitiesLazyQueryHookResult = ReturnType<typeof useAllEntitiesLazyQuery>;
export type AllEntitiesQueryResult = Apollo.QueryResult<AllEntitiesQuery, AllEntitiesQueryVariables>;
export const EntityDetailsDocument = gql`
    query EntityDetails($entityId: String!) {
  entityDetails(entityId: $entityId) {
    ...Entity
  }
}
    ${EntityFragmentDoc}`;

/**
 * __useEntityDetailsQuery__
 *
 * To run a query within a React component, call `useEntityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityDetailsQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useEntityDetailsQuery(baseOptions: Apollo.QueryHookOptions<EntityDetailsQuery, EntityDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EntityDetailsQuery, EntityDetailsQueryVariables>(EntityDetailsDocument, options);
      }
export function useEntityDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EntityDetailsQuery, EntityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EntityDetailsQuery, EntityDetailsQueryVariables>(EntityDetailsDocument, options);
        }
export type EntityDetailsQueryHookResult = ReturnType<typeof useEntityDetailsQuery>;
export type EntityDetailsLazyQueryHookResult = ReturnType<typeof useEntityDetailsLazyQuery>;
export type EntityDetailsQueryResult = Apollo.QueryResult<EntityDetailsQuery, EntityDetailsQueryVariables>;
export const GetActivityDocument = gql`
    query GetActivity {
  getActivity {
    ...ActivityMessage
  }
}
    ${ActivityMessageFragmentDoc}`;

/**
 * __useGetActivityQuery__
 *
 * To run a query within a React component, call `useGetActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
      }
export function useGetActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
        }
export type GetActivityQueryHookResult = ReturnType<typeof useGetActivityQuery>;
export type GetActivityLazyQueryHookResult = ReturnType<typeof useGetActivityLazyQuery>;
export type GetActivityQueryResult = Apollo.QueryResult<GetActivityQuery, GetActivityQueryVariables>;
export const GetShareDocument = gql`
    query GetShare($id: String!) {
  getShare(id: $id) {
    id
    note
    entityId
    entity {
      ...Entity
    }
    sourceUserId
    sourceUser {
      id
      phone
      username
      email
      name
    }
  }
}
    ${EntityFragmentDoc}`;

/**
 * __useGetShareQuery__
 *
 * To run a query within a React component, call `useGetShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShareQuery(baseOptions: Apollo.QueryHookOptions<GetShareQuery, GetShareQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShareQuery, GetShareQueryVariables>(GetShareDocument, options);
      }
export function useGetShareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShareQuery, GetShareQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShareQuery, GetShareQueryVariables>(GetShareDocument, options);
        }
export type GetShareQueryHookResult = ReturnType<typeof useGetShareQuery>;
export type GetShareLazyQueryHookResult = ReturnType<typeof useGetShareLazyQuery>;
export type GetShareQueryResult = Apollo.QueryResult<GetShareQuery, GetShareQueryVariables>;
export const LoadUserDocument = gql`
    query LoadUser($username: String!) {
  loadUser(username: $username) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useLoadUserQuery__
 *
 * To run a query within a React component, call `useLoadUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useLoadUserQuery(baseOptions: Apollo.QueryHookOptions<LoadUserQuery, LoadUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadUserQuery, LoadUserQueryVariables>(LoadUserDocument, options);
      }
export function useLoadUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadUserQuery, LoadUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadUserQuery, LoadUserQueryVariables>(LoadUserDocument, options);
        }
export type LoadUserQueryHookResult = ReturnType<typeof useLoadUserQuery>;
export type LoadUserLazyQueryHookResult = ReturnType<typeof useLoadUserLazyQuery>;
export type LoadUserQueryResult = Apollo.QueryResult<LoadUserQuery, LoadUserQueryVariables>;
export const MyAccountDocument = gql`
    query MyAccount {
  myAccount {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useMyAccountQuery__
 *
 * To run a query within a React component, call `useMyAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAccountQuery(baseOptions?: Apollo.QueryHookOptions<MyAccountQuery, MyAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAccountQuery, MyAccountQueryVariables>(MyAccountDocument, options);
      }
export function useMyAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAccountQuery, MyAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAccountQuery, MyAccountQueryVariables>(MyAccountDocument, options);
        }
export type MyAccountQueryHookResult = ReturnType<typeof useMyAccountQuery>;
export type MyAccountLazyQueryHookResult = ReturnType<typeof useMyAccountLazyQuery>;
export type MyAccountQueryResult = Apollo.QueryResult<MyAccountQuery, MyAccountQueryVariables>;
export const MySourcesDocument = gql`
    query MySources {
  mySources {
    id
    label
  }
}
    `;

/**
 * __useMySourcesQuery__
 *
 * To run a query within a React component, call `useMySourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySourcesQuery(baseOptions?: Apollo.QueryHookOptions<MySourcesQuery, MySourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MySourcesQuery, MySourcesQueryVariables>(MySourcesDocument, options);
      }
export function useMySourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MySourcesQuery, MySourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MySourcesQuery, MySourcesQueryVariables>(MySourcesDocument, options);
        }
export type MySourcesQueryHookResult = ReturnType<typeof useMySourcesQuery>;
export type MySourcesLazyQueryHookResult = ReturnType<typeof useMySourcesLazyQuery>;
export type MySourcesQueryResult = Apollo.QueryResult<MySourcesQuery, MySourcesQueryVariables>;
export const OnboardingStatusDocument = gql`
    query OnboardingStatus {
  onboardingStatus {
    hasCompleted
    introText
    questionsRemaining {
      questionId
      text
      subheader
      options
      required
      multiSelect
    }
  }
}
    `;

/**
 * __useOnboardingStatusQuery__
 *
 * To run a query within a React component, call `useOnboardingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingStatusQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingStatusQuery, OnboardingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingStatusQuery, OnboardingStatusQueryVariables>(OnboardingStatusDocument, options);
      }
export function useOnboardingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingStatusQuery, OnboardingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingStatusQuery, OnboardingStatusQueryVariables>(OnboardingStatusDocument, options);
        }
export type OnboardingStatusQueryHookResult = ReturnType<typeof useOnboardingStatusQuery>;
export type OnboardingStatusLazyQueryHookResult = ReturnType<typeof useOnboardingStatusLazyQuery>;
export type OnboardingStatusQueryResult = Apollo.QueryResult<OnboardingStatusQuery, OnboardingStatusQueryVariables>;