import { useEffect, useState } from "react";

import { EntityFragment, useEntityDetailsQuery } from "../../generated/graphql";

export const usePollForRecipe = (entity: EntityFragment) => {
  const shouldLoad = Boolean(entity.recipeUrl && !entity.recipeSummary);

  // TODO: We can probably try to isolate this specifically, but this way we can utilize the pre-empted query that kicked off in the background
  // Downside is we have to basically assume we're loading until it's clearly taken too long, then we can decide that we've errored
  const [timeExpired, setTimeExpired] = useState(false);

  const { stopPolling } = useEntityDetailsQuery({
    variables: { entityId: entity.id },
    pollInterval: 1000,
    skip: !shouldLoad,
  });

  useEffect(() => {
    setTimeout(() => {
      stopPolling();
      setTimeExpired(true);
    }, 7000);
  }, []);

  return {
    loading: shouldLoad && !timeExpired,
    failed: timeExpired && !entity.recipeSummary,
  };
};
