import * as Slider from "@radix-ui/react-slider";
import { useState } from "react";
import { ClassNameValue } from "tailwind-merge";
import { cn } from "../utils";

interface Props {
  editable: boolean;
  sentiment: number;
  onValueChange: (value: number) => void;
  className?: ClassNameValue;
}

export const RecSlider: React.FC<Props> = ({ editable, sentiment, onValueChange, className }) => {
  const boxOverlayWidth = editable ? 5000 : 5010;
  const [manualSentiment, setManualSentiment] = useState<number>();

  const value = manualSentiment ?? sentiment * 100 * (sentiment < 0 ? 1 : 2);

  return (
    <Slider.Root
      className={cn(
        "mt-4 py-4 relative flex items-center touch-none select-none h-5 w-full overflow-hidden",
        className
      )}
      disabled={!editable}
      min={-500}
      max={1000}
      value={[value]}
      onValueChange={(vals) => {
        const val = vals[0];
        setManualSentiment(val);
        onValueChange?.(val > 0 ? val / 200 : val / 100);
      }}
    >
      <Slider.Track className=" flex-grow">
        <div className="bg-gradient-to-r from-red-600 via-yellow-400 to-green-400 from-5% via-10% to-60% flex-grow rounded-full h-5" />
      </Slider.Track>
      <Slider.Thumb className="block h-5 w-5 bg-transparent overflow-hidden" aria-label="Sentiment">
        {editable && <div className={"block h-5 w-5 rounded-full ring-white ring-8"} />}
        <div
          className={`absolute -right-[5000px] top-0 h-full bg-white bg-opacity-80`}
          style={{ width: `${boxOverlayWidth}px` }}
        />
        <div className={`absolute -top-1 -right-[5000px] h-3 bg-white`} style={{ width: `${boxOverlayWidth}px` }} />
        <div className={`absolute -bottom-1 -right-[5000px] h-3 bg-white`} style={{ width: `${boxOverlayWidth}px` }} />
        <div className={`absolute -top-1 -left-[5000px] h-2.5 bg-white`} style={{ width: `${boxOverlayWidth}px` }} />
        <div className={`absolute -bottom-1 -left-[5000px] h-2.5 bg-white`} style={{ width: `${boxOverlayWidth}px` }} />
      </Slider.Thumb>
    </Slider.Root>
  );
};
