import { some } from "lodash";
import { createContext, useContext, useRef, useState } from "react";

import { useIsLoggedIn } from "@/auth/user";
import {
  ListMetadataFragment,
  SavedItemFragment,
  SearchResponseV2Fragment,
  SearchResultV2Fragment,
  useMyListsQuery,
  useMySavedItemsQuery,
} from "../../generated/graphql";

type SearchFeedback = "up" | "down";
export type SearchResult = Omit<SearchResultV2Fragment, "__typename">;

const matches = (source: string | undefined, term: string, opts?: { loose?: boolean; leadingOnly?: boolean }) => {
  const adjustedTerm = term
    .toLowerCase()
    .trim()
    // See https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
    // Ensures that we have no special characters messing up our regex
    .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    .replace(opts?.loose ? /i?e?s?$/ : "", "");
  const regex = new RegExp((opts?.leadingOnly ? String.raw`\b` : "") + adjustedTerm);
  return Boolean(source && source.toLowerCase().match(regex));
};

export const SavorContext = createContext({
  inputRef: undefined as React.RefObject<HTMLInputElement> | undefined,
  focusInput: () => {},

  itemsLoading: false,
  savedItems: [] as SavedItemFragment[],
  filteredContent: [] as SavedItemFragment[],
  myLists: [] as ListMetadataFragment[],

  searchBarText: "" as string | undefined,
  setSearchBarText: ((val: string) => {}) as (val: string) => void,

  searchResponse: undefined as SearchResponseV2Fragment | undefined,
  setSearchResponse: ((val: any) => {}) as (val: SearchResponseV2Fragment | undefined) => void,

  searchFeedback: {} as { [key: string]: SearchFeedback },
  setSearchFeedback: ((id: string, val: any) => {}) as (id: string, val: SearchFeedback | null) => void,

  searchVisible: false,
  setSearchVisible: ((val: boolean) => {}) as (val: boolean) => void,

  // TODO: This can be removed in favor of onClickSearchResult
  targetListId: undefined as string | undefined,
  setTargetListId: ((val: string) => {}) as React.Dispatch<React.SetStateAction<string | undefined>>,

  onClickSearchResult: undefined as ((val: SearchResult) => void) | undefined,
  setOnClickSearchResult: (() => {}) as (val: ((v: SearchResult) => void) | undefined) => void,

  mobile: false,
  standalone: false,
});

// WARNING: THERE IS A KNOWN HOT RELOAD ISSUE WHEN YOU EDIT THIS FILE. YOU MAY HAVE TO HARD REFRESH YOUR BROWSER WINDOW WHEN MAKING CHANGES
export const SavorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const loggedIn = useIsLoggedIn();
  const { data: mySavedItems, loading } = useMySavedItemsQuery({ skip: !loggedIn });
  const { data: { myLists } = { myLists: [] } } = useMyListsQuery({ skip: !loggedIn });
  const [targetListId, setTargetListId] = useState<string>();

  const [searchFeedback, setFeedback] = useState<{ [key: string]: SearchFeedback }>({});
  const setSearchFeedback = (id: string, val: SearchFeedback | null) =>
    setFeedback((curr) => {
      if (!val) {
        const { [id]: _, ...rest } = curr;
        return rest;
      }
      return { ...curr, [id]: val };
    });
  const [searchResponse, setResponse] = useState<SearchResponseV2Fragment>();
  const setSearchResponse = (val: SearchResponseV2Fragment | undefined) => {
    setResponse(val);
    if (!val) {
      setFeedback({});
    }
  };
  const [searchBarText, setText] = useState<string>("");
  const setSearchBarText = (val: string) => {
    setText(val);
    if (!val) {
      setSearchResponse(undefined);
    }
  };
  const [onClickSearchResult, setOnClickSearchResult] = useState<(v: SearchResultV2Fragment) => void>();

  const inputRef = useRef<HTMLInputElement>(null);
  const focusInput = () => inputRef.current?.focus();
  const savedItems = mySavedItems?.savedItemsForUser ?? [];
  // TODO: Could do a more robust match than on entity title alone
  const matchingItems = savedItems.filter(
    (si) =>
      // Only include title-based matches if we are in a specific search mode
      matches(si.entity?.title, searchBarText) ||
      (searchResponse !== undefined
        ? false
        : matches(si.entity?.type, searchBarText, { loose: true }) ||
          matches(si.entity?.description, searchBarText, { leadingOnly: true }) ||
          matches(si.entity?.links.map((l) => `${l.url}${l.label ?? ""}`).join(""), searchBarText) ||
          matches(si.entity?.recipeSummary ?? "recipe", searchBarText) ||
          some(si.lists.map((t) => matches(t.label, searchBarText, { loose: true }))) ||
          some(si.sources.map((s) => matches(s.label, searchBarText, { loose: true }))) ||
          some(si.entity?.notes.map((c) => matches(c.text, searchBarText))))
  );
  const [searchVisible, setSearchVisible] = useState(false);
  const mobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
  const standalone = window.matchMedia("(display-mode: standalone)").matches;

  return (
    <SavorContext.Provider
      value={{
        inputRef,
        focusInput,
        itemsLoading: loading,
        savedItems,
        filteredContent: searchBarText.length > 2 ? matchingItems : [],
        myLists,
        searchBarText,
        setSearchBarText,
        searchResponse,
        setSearchResponse,
        searchFeedback,
        setSearchFeedback,
        searchVisible,
        setSearchVisible: (val: boolean) => {
          setSearchVisible(val);
          if (val) {
            focusInput();
          } else {
            setSearchBarText("");
            // TargetListId should never persist across searches
            setTargetListId(undefined);
          }
        },
        targetListId,
        setTargetListId,
        onClickSearchResult,
        setOnClickSearchResult: (fn?: (v: SearchResultV2Fragment) => void) => setOnClickSearchResult(() => fn),
        mobile,
        standalone,
      }}
    >
      {children}
    </SavorContext.Provider>
  );
};

export const useSavor = () => {
  const context = useContext(SavorContext);

  return context;
};
