import { useAppNavigate } from "@/hooks/use-app-navigate";
import { useSavor } from "@/hooks/use-savor";
import { compact } from "lodash";
import { ImageWithFallback } from "../image-with-fallback";

export const SearchPanelInformation: React.FC = () => {
  const { searchResponse, setSearchBarText, filteredContent, onClickSearchResult } = useSavor();
  const navigate = useAppNavigate();

  if (!searchResponse) {
    return null;
  }

  const filteredContentIds = new Set(compact(filteredContent.map((f) => f.entity?.id)));
  const results = [
    ...searchResponse.myResults,
    ...searchResponse.networkResults,
    ...searchResponse.internetResults,
  ].filter((e) => e.entityId && !filteredContentIds.has(e.entityId));

  return (
    <>
      {results.map((i) => {
        const fullLabel = `${i.title}${i.title.match(/.*\(.*\)$/) ? "" : ` (${i.type})`}`;
        return (
          <div
            key={fullLabel}
            className="cursor-pointer flex items-center gap-4 px-4 text-lg truncate flex-shrink-0"
            onClick={(e) => {
              if (onClickSearchResult) {
                onClickSearchResult(i);
              } else if (i.savorUrl) {
                navigate(i.savorUrl);
              }
              setSearchBarText(fullLabel);
              // TODO: Super hacky, but a proof of concept
              setTimeout(
                () =>
                  document
                    .getElementById("savor")
                    ?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true })),
                // Delay enough to let the value update
                200
              );
              e.stopPropagation();
            }}
          >
            <ImageWithFallback src={i.imageUrl} className="h-8 flex-none object-cover rounded shadow w-8" />
            <span className="flex-auto truncate">{fullLabel}</span>
          </div>
        );
      })}
    </>
  );
};
