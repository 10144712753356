import { useParams } from "react-router";

import { Loading } from "@/components/ui/loading";
import { useGetShareQuery } from "../../generated/graphql";
import { Entity } from "./entity";

export const SharedEntity: React.FC = () => {
  const { shareId } = useParams();
  const { data: shareData, loading } = useGetShareQuery({ variables: { id: shareId! }, skip: !shareId });

  const { sourceUser, entity, note } = shareData?.getShare ?? {};
  if (loading) {
    return <Loading />;
  } else if (!shareId || !sourceUser || !entity) {
    return null;
  }
  return <Entity entityId={entity.id} fromUsername={sourceUser.username} recommendersNote={note ?? undefined} />;
};
