import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import cacheConfig from "../generated/cacheConfig";
import { getAccessToken } from "../site/auth/atoms";
import { invalidationLink } from "./invalidationLink";

const apiHttpLink = createHttpLink({
  uri: "/graphql",
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: unknown) => (key === "__typename" ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename) as Record<string, unknown>;
  }
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  // eslint-disable-next-line unicorn/prefer-spread
  link: cleanTypeName.concat(invalidationLink.concat(authLink.concat(apiHttpLink))),
  queryDeduplication: true,
  cache: new InMemoryCache({
    ...cacheConfig,
  }),
  defaultOptions: {
    query: {
      // Default to not using the cache.
      // We can slowly enable this once we have a good setup for invalidating the cache.
      fetchPolicy: "network-only",
    },
    watchQuery: {
      // Apollo Client executes the full query against both the cache and your GraphQL server. The query automatically updates if the result of the server-side query modifies cached fields.
      // Provides a fast response while also helping to keep cached data consistent with server data.
      fetchPolicy: "cache-and-network",
    },
  },
});
