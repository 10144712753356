import { Loading } from "@/components/ui/loading";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useProfileUser } from "@/hooks/use-profile-user";
import { useMyAccount } from "../hooks/use-my-account";
import { ListOfLists } from "../lists/list-of-lists";
import { Library } from "../mystuff/library";
import { ProfileHeader } from "./profile-header";

export const Profile: React.FC<{ me?: boolean }> = ({ me }) => {
  const loggedInUser = useMyAccount();
  const { user, lists, loading } = useProfileUser();

  if (!user) return null;

  if (!loading && !lists) {
    // TODO: should be a redirect
    return <p>User not found/profile could not be loaded</p>;
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <ProfileHeader user={user} me={loggedInUser?.id === user.id} />
      <Tabs defaultValue="library">
        <TabsList className="flex shadow sticky top-0 z-20">
          <TabsTrigger value="library">Library</TabsTrigger>
          <TabsTrigger value="lists">Lists</TabsTrigger>
        </TabsList>
        <TabsContent value="library">
          <Library />
        </TabsContent>
        <TabsContent value="lists">
          <ListOfLists lists={lists!} />
        </TabsContent>
      </Tabs>
    </>
  );
};
