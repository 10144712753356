import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";
import { Icon, IconString } from "./ui/icon";

export const FakeSearch = ({ label = "What brings you here today?" }: { label?: string }) => {
  const { setSearchVisible } = useSavor();

  return (
    <SearchContainer>
      <div className={cn(megaClassName, "bg-background text-muted-foreground")} onClick={() => setSearchVisible(true)}>
        <Icon icon="search" className={iconClassName} />
        <span className="flex-auto truncate">{label}</span>
      </div>
    </SearchContainer>
  );
};

export const MegaButton = ({
  label,
  leftIcon,
  rightIcon,
  onClick,
}: {
  label: string;
  leftIcon?: IconString;
  rightIcon?: IconString;
  onClick: () => void;
}) => (
  <SearchContainer>
    <div className={cn(megaClassName, "bg-primary cursor-pointer text-background")} onClick={onClick}>
      {leftIcon && <Icon icon={leftIcon} className={iconClassName} />}
      <span className="flex-auto text-lg truncate">{label}</span>
      {rightIcon && <Icon icon={rightIcon} className={iconClassName} />}
    </div>
  </SearchContainer>
);

export const SearchContainer = ({ children }: { children: React.ReactNode }) => {
  const { standalone } = useSavor();

  return (
    <aside
      className={cn(
        "fixed flex gap-4 justify-center items-center left-0 max-w-xl m-auto px-4 mb-12 right-0 z-40",
        standalone ? "bottom-16" : "bottom-8"
      )}
    >
      {children}
    </aside>
  );
};

const megaClassName = "flex flex-auto gap-5 h-16 items-center px-6 relative rounded-xl shadow-xl z-1";
const iconClassName = "filled flex items-center justify-center text-2xl w-[1em]";
