import { getServiceWorkerRegistration } from "../..";

// Could be a vercel build-time secret, but likely fine here
const VAPID_PUBLIC_KEY = "BLq5sgXJjRdjZb2G6xb0oBvZzNgc_6uCLo0D7oKOQ2m8UYLPpV73MbEVGRZ6rv3xLz7zitrTaFaxRfC1YABNiKM";

// From https://knock.app/blog/how-to-send-browser-push-notifications-from-nodejs
const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const subscribeToPush = async (userId: string) => {
  try {
    let pushManager = (await getServiceWorkerRegistration())?.pushManager;
    let subscription =
      (await pushManager?.getSubscription()) ??
      (await pushManager?.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
      }));

    if (subscription) {
      await fetch("/api/push/subscribe", {
        method: "POST",
        body: JSON.stringify({ ...subscription?.toJSON(), userId }),
        headers: {
          "content-type": "application/json",
        },
      });
    }
  } catch (e) {
    // Could sentry log this to see all the users for whom this is not working
    console.error(e);
  }
};
