import { CSSProperties } from "react";
import { useParams } from "react-router";

import { Items, ItemsContainer } from "@/components/items";
import { useMyAccount } from "@/hooks/use-my-account";
import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";
import { getListColorScheme } from "@/utils/list-colors";
import { useListDetailsQuery } from "../../generated/graphql";
import { ListHeader } from "./list-header";

export const List: React.FC = () => {
  const myAccount = useMyAccount();
  const { listId } = useParams();
  const { targetListId, searchVisible, setSearchVisible, setTargetListId } = useSavor();

  // TODO: Resolve this confusing tags/list nomenclature
  const { data: { listDetails } = {} } = useListDetailsQuery({
    variables: { listId: listId! },
    skip: !listId,
  });

  if (!listDetails) {
    return null;
  }

  const isMe = listDetails.userId === myAccount?.id;
  const isTarget = targetListId === listId;

  return (
    <>
      <div
        className={cn(
          isTarget && searchVisible ? "" : "z-50",
          "absolute bg-background inset-0 overflow-y-auto text-foreground w-full"
        )}
        style={
          {
            "--background": `${getListColorScheme(listDetails.type).hslHue} 90% 91%`,
            "--foreground": `${getListColorScheme(listDetails.type).hslHue} 55% 45%`,
            "--border": `${getListColorScheme(listDetails.type).hslHue} 32% 48%`,
            "--primary": `1 100% 100%`,
          } as CSSProperties
        }
      >
        <ListHeader list={listDetails} />
        <div className="max-w-2xl w-full mx-auto mt-16 mb-24">
          {listDetails.description && <p className="text-gray-500">{listDetails.description}</p>}
          <ItemsContainer>
            <Items items={listDetails.savedItems} fromUsername={isMe ? undefined : listDetails.username} />
            {isMe && (
              <button
                className="border-dashed border-8 border-gray-200 text-gray-500 aspect-square p-4 rounded text-center bg-white bg-opacity-70 z-1"
                onClick={() => {
                  setTargetListId(listId);
                  setSearchVisible(true);
                }}
              >
                Click to add to this list
              </button>
            )}
          </ItemsContainer>
        </div>
      </div>
    </>
  );
};
