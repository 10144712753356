import { formatDistanceToNowStrict } from "date-fns";
import locale from "date-fns/locale/en-US";

const formatDistanceLocale = {
  lessThanXSeconds: "{{count}}s",
  xSeconds: "{{count}}s",
  halfAMinute: "30s",
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}m",
  xMonths: "{{count}}m",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
};

function formatDistance(token: any, count: any, options: any) {
  options = options || {};

  const result = (formatDistanceLocale as any)[token].replace("{{count}}", count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }

  return result;
}

export const formatDistanceToNow = (datems: number | Date, options?: { addSuffix?: boolean }) =>
  formatDistanceToNowStrict(datems, {
    addSuffix: options?.addSuffix ?? true,
    locale: {
      ...locale,
      formatDistance,
    },
  });
