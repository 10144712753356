import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Loading } from "@/components/ui/loading";
import { Table, TableCell, TableHeader, TableRow } from "@/components/ui/table";
import { useAllEntitiesQuery, useGenerateThumbnailMutation } from "../../generated/graphql";

export const Entolio: React.FC = () => {
  const [generatingForId, setGeneratingForId] = useState<string>();
  const { data: { allEntities } = {}, loading } = useAllEntitiesQuery();

  const [generateThumb] = useGenerateThumbnailMutation();

  const onClickGenerate = (entityId: string) => {
    setGeneratingForId(entityId);
    generateThumb({
      variables: { entityId },
      onCompleted: () => setGeneratingForId(undefined),
      onError: () => setGeneratingForId(undefined),
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {allEntities && (
        <Table>
          <TableHeader>
            <TableCell>ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Image URL</TableCell>
            <TableCell>Thumbnail URL</TableCell>
          </TableHeader>
          {allEntities.map((e, idx) => (
            <TableRow key={e.id}>
              <TableCell>{e.id}</TableCell>
              <TableCell className="max-w-2xl">{e.title}</TableCell>
              <TableCell>{e.type}</TableCell>
              <TableCell>{e.imageUrl}</TableCell>
              <TableCell>
                {generatingForId === e.id ? (
                  <Loading />
                ) : e.thumbnailUrl === e.imageUrl ? (
                  <Button onClick={() => onClickGenerate(e.id)}>Generate Thumbnail</Button>
                ) : (
                  e.thumbnailUrl
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      )}
    </div>
  );
};
