import { useEffect } from "react";

import { ImageHeader } from "@/components/image-header";
import { useAppNavigate } from "@/hooks/use-app-navigate";
import { ActivityFeed } from "../home/activity-feed";
import { PublicIntro } from "../home/public-intro";
import { useIsLoggedIn } from "./user";

export const LoginPage: React.FC = () => {
  const navigate = useAppNavigate();
  const loggedIn = useIsLoggedIn();

  useEffect(() => {
    if (loggedIn) {
      navigate("/home");
    }
  }, [loggedIn]);

  return (
    <>
      <header className="bg-background flex h-16 items-center justify-center shadow-sm sticky top-0 z-20">
        <ImageHeader className="text-4xl">Savor</ImageHeader>
      </header>
      <div className="flex flex-col gap-6 items-center max-w-4xl mx-auto px-6">
        <PublicIntro />
        <ActivityFeed />
      </div>
    </>
  );
};
