import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";
import { compact } from "lodash";
import { SavedItemFragment } from "../../../generated/graphql";
import { Icon } from "../ui/icon";

export const SearchHeader: React.FC<{ latestItems: SavedItemFragment[] }> = ({ latestItems }) => {
  const { setSearchVisible, searchBarText, setSearchBarText, searchResponse, setSearchResponse, focusInput } =
    useSavor();

  // TODO: Entity type, tags and sources should be merged
  // But until then, this helps serve as a "most recent"
  const mostRecentFilterables = compact(
    latestItems.flatMap((si) => [
      ...si.lists.map((t) => t.label),
      si.entity?.type ?? undefined,
      ...si.sources.map((s) => s.label),
    ])
  );

  const getFirstNUnique = (n: number) => {
    const result: string[] = [];
    for (const filterable of mostRecentFilterables) {
      if (!result.includes(filterable)) {
        result.push(filterable);
      }
      if (result.length >= n) {
        break;
      }
    }
    return result;
  };

  const collections = getFirstNUnique(20);

  return (
    <header className="bg-background flex-none shadow-xs sticky top-0">
      <div className="flex items-center" onClick={focusInput}>
        <button
          className="flex h-16 items-center justify-center w-16"
          onClick={(e) => {
            setSearchBarText("");
            if (searchResponse) {
              setSearchResponse(undefined);
            }
            setSearchVisible(false);
            e.stopPropagation();
          }}
        >
          <Icon icon="arrow_back" />
        </button>
        Search
      </div>
      {collections[0] && (
        <div className="overflow-x-auto p-3 pt-0">
          <ul className="flex items-center gap-2">
            {collections.map((c) => (
              <li
                key={c}
                className={cn(
                  "cursor-pointer flex-none font-semibold px-4 py-2 rounded-full text-xs",
                  searchBarText === c ? "bg-foreground text-background" : "bg-muted text-foreground"
                )}
                onClick={() => {
                  if (searchBarText !== c) {
                    setSearchBarText(c);
                  } else {
                    setSearchBarText("");
                  }
                }}
              >
                {c}
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};
