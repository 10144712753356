import { compact, orderBy, partition } from "lodash";
import React from "react";

import { ThreeDotMenu } from "@/components//three-dot-menu";
import { Thumbnail } from "@/components//thumbnail";
import { Icon } from "@/components//ui/icon";
import { Button } from "@/components/ui/button";
import { useAppNavigate } from "@/hooks/use-app-navigate";
import { useMyAccount } from "@/hooks/use-my-account";
import { useProfileUser } from "@/hooks/use-profile-user";
import { useSavor } from "@/hooks/use-savor";
import {
  ListMetadataFragment,
  ListType,
  MyListsDocument,
  SavedItemFragment,
  useUpdateListMutation,
} from "../../generated/graphql";

interface Props {
  lists: (ListMetadataFragment & { savedItems?: SavedItemFragment[] })[];
}

export const ListOfLists: React.FC<Props> = ({ lists }) => {
  const myAccount = useMyAccount();
  const navigate = useAppNavigate();
  const { user: profileUser } = useProfileUser();
  // TODO: All four of these things will have to account for the auth scoping
  const { savedItems: allSavedItems, setSearchVisible, setTargetListId } = useSavor();

  const [updateList] = useUpdateListMutation();

  const DEFAULT_LISTS = ["Hall of Fame", "Hit List", "The Queue", "The Pile"];
  const [defaultLists, otherLists] = partition(lists ?? [], (t) => DEFAULT_LISTS.includes(t.label));
  const initialLists = compact(DEFAULT_LISTS.map((dl) => defaultLists.find((t) => t.label === dl)));

  const orderedLists = [...initialLists, ...orderBy(otherLists, (l) => l.updatedAt, "desc")];

  return (
    <>
      {orderedLists.map(
        ({ id, label, description, savedItems: fetchedItems, type, userId, username, public: isPublic, deletedAt }) => {
          const isMine = userId === myAccount?.id;

          const savedItems = fetchedItems ?? allSavedItems.filter((si) => si.lists.find((t) => t.id === id));

          if (!isMine && savedItems.length === 0 && type !== ListType.HallOfFame) {
            return null;
          }

          const items =
            savedItems?.map((si) => (
              <Thumbnail
                className="h-32 aspect-square flex-shrink-0"
                key={si.id}
                alt={si.title}
                src={si.entity?.thumbnailUrl ?? undefined}
                reaction={si.reaction}
                onClick={() => {
                  if (si.entity) {
                    navigate(`/entity/${si.entity.id}`);
                  }
                }}
              />
            )) ?? [];

          return (
            <section id={id} key={label} className="border-b pb-2">
              <header className="flex flex-row items-start justify-between p-6 pb-4">
                <div className="space-y-2">
                  <h2 onClick={() => navigate(`/lists/${id}`)} className="flex gap-2 items-center cursor-pointer">
                    {label}
                    {!isMine && !profileUser && ` (@${username})`}
                    <Icon icon="arrow_forward" />
                  </h2>
                  <p className="text-muted-foreground text-sm">{description}</p>
                </div>
                {isMine && (
                  <div className="flex gap-4 items-center">
                    <Button
                      variant="outline"
                      onClick={(e) => {
                        setTargetListId(id);
                        setSearchVisible(true);
                      }}
                    >
                      Add to list
                    </Button>
                    <ThreeDotMenu
                      isPublic={isPublic}
                      setPublic={(v) => updateList({ variables: { input: { listId: id, public: v } } })}
                      deleteFn={() => {
                        const proceed = confirm("Are you sure you want to delete this list?");
                        if (proceed) {
                          updateList({
                            variables: { input: { listId: id, shouldDelete: true } },
                            refetchQueries: [MyListsDocument],
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </header>
              {items[0] && <div className="flex gap-2 overflow-x-auto px-6 pb-4">{items}</div>}
            </section>
          );
        }
      )}
    </>
  );
};
