import { useEffect, useState } from "react";

export const Viewport = () => {
  const [showDebug] = useState(false);
  const [viewportOffset, setViewportOffset] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const viewport = window.visualViewport;
  const style = document.documentElement.style;

  const viewportUpdate = () => {
    style.setProperty("--viewportOffset", `${viewport?.offsetTop}px`);
    setViewportOffset(viewport?.offsetTop || 0);

    style.setProperty("--windowHeight", `${window.innerHeight}px`);
    setWindowHeight(window.innerHeight);
  };

  viewport?.addEventListener("resize", () => {
    viewportUpdate();
  });

  useEffect(viewportUpdate, []);

  return showDebug ? (
    <div className="bg-background border fixed p right z-[60]" style={{ top: "var(--viewportOffset)" }}>
      <div>
        viewportOffset: {viewportOffset} | {viewport?.offsetTop}
      </div>
      <div>
        windowHeight: {windowHeight} | {viewport?.offsetTop}
      </div>
    </div>
  ) : null;
};
