import { ImageWithFallback } from "@/components/image-with-fallback";
import { buttonClassNames } from "@/components/three-dot-menu";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { useAppBack } from "@/hooks/use-app-navigate";
import { cn } from "@/utils";
import { UserFragment } from "src/generated/graphql";
import { ProfilePhoto } from "./profile-photo";

export const ProfileHeader = ({ me = false, user }: { me?: boolean; user: UserFragment }) => {
  const goBack = useAppBack();

  return (
    <header className="flex h-16 items-center justify-center px-4 relative">
      {!me && (
        <button className={cn(buttonClassNames, "absolute inset-4 right-auto")} onClick={goBack}>
          <Icon icon="arrow_back" />
        </button>
      )}
      <h1 className="text-xl">{user.name ?? user.phone}</h1>
      <Dialog>
        <DialogTrigger disabled={!me}>
          <ImageWithFallback
            src={user?.profilePhoto ?? "/images/tbd"}
            className="absolute inset-4 left-auto rounded-full shadow h-8 w-8 object-cover"
          />
        </DialogTrigger>
        <DialogContent>
          <DialogTitle>Edit Profile</DialogTitle>
          <ProfilePhoto />
        </DialogContent>
      </Dialog>
    </header>
  );
};
