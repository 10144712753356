import { encode } from "urlencode";

import { NavAnchor, NavButton } from "@/components/nav";
import { useToast } from "@/components/ui/use-toast";
import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";
import { EntityFragment } from "../../generated/graphql";

interface Props {
  entity: EntityFragment;
  shareId?: string;
  handleClose: () => void;
}

export const Share: React.FC<Props> = ({ entity, shareId, handleClose }) => {
  const { standalone } = useSavor();
  const { toast } = useToast();

  if (!shareId) {
    return null;
  }

  const shareUrl = `${window.location.protocol}//${window.location.host}/shared/${shareId}`;

  return (
    <>
      <div className="bg-background/80 fixed inset-0 z-40" onClick={handleClose} />
      <aside
        className={cn(
          "bg-primary bottom-0 fixed left-0 max-h-dvh max-w-sm mx-auto overflow-x-auto pt-2 right-0 rounded-t-2xl shadow-3xl text-background z-50",
          standalone && "pb-8"
        )}
      >
        <div className="flex gap-2 h-20 items-center justify-center">
          <NavButton
            icon="copy_all"
            title="Copy Link"
            onClick={async () => {
              await navigator.clipboard.writeText(shareUrl);
              toast({ title: "Copied to clipboard!" });
            }}
            className="text-inherit"
          />
          <NavAnchor
            icon="chat"
            title="Text"
            href={`sms://?&body=Hey, check out ${encode(entity.title)} on Savor ${shareUrl}`}
            className="text-inherit"
          />
          <NavAnchor
            icon="mail"
            title="Email"
            href={`mailto:?subject=Hey, check out ${encode(entity.title)} on Savor&body=${shareUrl}`}
            className="text-inherit"
          />
          {navigator.share && (
            <NavButton
              icon="ios_share"
              title="Share"
              onClick={async () =>
                await navigator.share({
                  title: entity.title,
                  text: `Hey, check out ${entity.title} on Savor`,
                  url: shareUrl,
                })
              }
              className="text-inherit"
            />
          )}
        </div>
      </aside>
    </>
  );
};
