import { useSavor } from "@/hooks/use-savor";

// Unused for now, but this is where listicle parsing and article parsing will go.
export const SearchPanelUrl: React.FC = () => {
  const { searchResponse } = useSavor();

  if (!searchResponse) {
    return null;
  }

  return (
    <>
      <h2>Looks like you're trying to save a website.</h2>
    </>
  );
};
