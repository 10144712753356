import { formatDistanceToNow } from "@/utils/format-distance-to-now";
import { useEffect, useRef, useState } from "react";
import {
  LoadRecommendationsDocument,
  RecommendationFragment,
  useUpdateRecommendationMutation,
} from "../../generated/graphql";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { useAppNavigate } from "../hooks/use-app-navigate";
import { useMyAccount } from "../hooks/use-my-account";
import { RecImage } from "./rec-image";
import { RecSlider } from "./rec-slider";
import { useSentiment } from "./use-sentiment";

interface Props {
  rec: RecommendationFragment;
}

export const Recommendation: React.FC<Props> = ({ rec }) => {
  const user = useMyAccount();
  const navigate = useAppNavigate();
  const { calculate } = useSentiment();
  const [title, setTitle] = useState(rec.entity.title);
  const [updatedImage, setUpdatedImage] = useState<string>();
  const [editable, setEditable] = useState(false);
  const [editText, setEditText] = useState<string>();
  const [sentiment, setSentiment] = useState(rec.sentiment);
  const ref = useRef<HTMLTextAreaElement>(null);
  const [saveRec, { loading: saving }] = useUpdateRecommendationMutation();

  if (editable) {
    console.log({ sentiment });
  }

  const relativeTime = formatDistanceToNow(new Date(rec.createdAt), { addSuffix: false });

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "0px";
      const scrollHeight = ref.current?.scrollHeight;
      ref.current.style.height = scrollHeight + "px";
    }
  }, [editText]);

  return (
    <div className="flex flex-col p-4 rounded border border-1 border-bg max-w-2xl">
      <div key={rec.id} className="flex flex-row gap-4 items-center">
        <RecImage
          key={`${editable}`}
          image={rec.imageUrl ?? rec.entity.thumbnailUrl ?? rec.entity.imageUrl ?? undefined}
          user={rec.user}
          editable={editable}
          setSaveableImage={(uri) => setUpdatedImage(uri)}
        />
        <div className="flex flex-col" style={{ flex: 3 }}>
          <div className="flex flex-row items-baseline gap-x-2 flex-wrap">
            {editable ? (
              <Input className="text-lg w-min" value={title} onChange={(e) => setTitle(e.target.value)} />
            ) : (
              <h2 className="cursor-pointer" onClick={() => navigate(`/entity/${rec.entity.id}`)}>
                {rec.entity.title}
              </h2>
            )}
            <p className="text-gray-300">({rec.entity.type})</p>
          </div>
          <div className="flex flex-row gap-4 items-baseline">
            <p className="cursor-pointer text-primary" onClick={() => navigate(`/profile/${rec.user.username}`)}>
              @{rec.user.username}
            </p>
            <h6 className="text-muted-foreground text-xs items-end">{relativeTime}</h6>
          </div>
          {editable ? (
            <textarea
              ref={ref}
              className="text-sm"
              value={editText}
              onChange={(e) => {
                setEditText(e.target.value);
                setSentiment(calculate(e.target.value));
              }}
            />
          ) : (
            <p
              className="italic text-muted-foreground text-sm"
              dangerouslySetInnerHTML={{ __html: rec.text.replaceAll("\n", "<br />") }}
            />
          )}
        </div>
      </div>
      <RecSlider
        key={`${editable}`}
        editable={editable}
        sentiment={sentiment}
        onValueChange={(val) => setSentiment(val)}
      />
      <div className="flex flex-row w-full justify-end gap-2">
        {user?.id === rec.user.id && (
          <>
            {editable ? (
              <>
                <Button
                  disabled={saving}
                  onClick={() => {
                    saveRec({
                      variables: { input: { recId: rec.id, text: editText, sentiment, title, imageUrl: updatedImage } },
                    }).then(() => setEditable(false));
                  }}
                >
                  {saving ? "Saving..." : "Save"}
                </Button>
                <Button
                  variant="ghost"
                  disabled={saving}
                  onClick={() => {
                    setEditable(false);
                    setEditText(rec.text);
                    setSentiment(rec.sentiment);
                    setTitle(rec.entity.title);
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                variant="ghost"
                onClick={() => {
                  setEditable((curr) => !curr);
                  setEditText(editable ? undefined : rec.text);
                }}
              >
                Edit
              </Button>
            )}
            <Button
              disabled={saving}
              variant="ghost"
              className="text-destructive"
              onClick={async () => {
                const yes = await confirm("Are you sure you want to delete this recommendation?");
                if (yes) {
                  saveRec({
                    variables: { input: { recId: rec.id, shouldDelete: true } },
                    refetchQueries: [LoadRecommendationsDocument],
                  });
                }
              }}
            >
              Delete
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
