import { ListType } from "../../generated/graphql";

export const getListColorScheme = (type: ListType): { className: string; hslHue: number } => {
  switch (type) {
    case ListType.HallOfFame:
      return { className: "bg-orange-100 border-orange-300", hslHue: 36 };
    case ListType.Queue:
      return { className: "bg-blue-100 border-blue-300", hslHue: 190 };
    case ListType.Guide:
      return { className: "bg-green-100 border-green-300", hslHue: 90 };
    case ListType.Gift:
      return { className: "bg-red-100 border-red-300", hslHue: 250 };
    default:
      return { className: "bg-gray-100 border-gray-300", hslHue: 20 };
  }
};
