import { orderBy } from "lodash";

import { useAppNavigate } from "@/hooks/use-app-navigate";
import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";
import { SearchQueryType, useSearchV2LazyQuery } from "../../../generated/graphql";
import { SearchBar } from "./search-bar";
import { SearchFilteredContent } from "./search-filtered-content";
import { SearchHeader } from "./search-header";
import { SearchIntro } from "./search-intro";
import { SearchPanelInformation } from "./search-panel-information";
import { SearchPanelUrl } from "./search-panel-url";

export const SearchPanel: React.FC<{}> = () => {
  const { searchResponse, setSearchResponse, filteredContent, searchVisible, standalone, onClickSearchResult } =
    useSavor();
  const navigate = useAppNavigate();
  const latestItems = orderBy(filteredContent, (si) => si.updatedAt, "desc");

  const searchMode = searchResponse?.type;

  const [executeSearch, { loading }] = useSearchV2LazyQuery({ fetchPolicy: "cache-and-network" });

  const search = (query: string) => {
    executeSearch({
      variables: { term: query },
      onCompleted: ({ searchV2 }) => {
        setSearchResponse(searchV2);
        if (searchV2.type === SearchQueryType.Search && searchV2.internetResults.length === 1) {
          const url = searchV2.internetResults[0].savorUrl;
          if (url && !onClickSearchResult) {
            navigate(url);
          }
        }
      },
    });
  };

  return (
    <div
      className={cn(
        "bg-background bottom-0 fixed flex flex-col inset-0 overflow-y-auto overscroll-none z-40",
        searchVisible ? "search-visible opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none",
        standalone && "pb-6"
      )}
      style={{ height: "calc(var(--windowHeight)", top: "var(--viewportOffset)" }}
      id="searchPanel"
    >
      <SearchHeader latestItems={latestItems} />
      <div className="flex flex-col h-full mt-4 gap-4 overflow-y-auto ">
        <SearchFilteredContent />
        {searchMode === undefined && !filteredContent.length && <SearchIntro />}
        {searchMode === SearchQueryType.Search && <SearchPanelInformation />}
        {searchMode === SearchQueryType.Url && <SearchPanelUrl />}
      </div>
      <SearchBar search={search} loading={loading} />
    </div>
  );
};
