import { FakeSearch } from "@/components/fake-search";
import { ImageHeader } from "@/components/image-header";
import { ActivityFeed } from "./activity-feed";

export const Home: React.FC = () => (
  <>
    <header className="bg-background flex h-16 items-center justify-center shadow-sm sticky top-0 z-20">
      <ImageHeader className="text-4xl">Savor</ImageHeader>
    </header>
    <div className="flex flex-col gap-4 items-center max-w-4xl mx-auto px-6">
      {/* <HomeAlertsSection /> */}
      <ActivityFeed />
      <FakeSearch />
    </div>
  </>
);
