import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";
import { Icon } from "./ui/icon";
import { Loading } from "./ui/loading";

export const DrawerOverlay = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("drawer-visible fixed inset-0 z-40", className)} {...props} />
);

export const Drawer = ({
  children,
  handleClose,
  title,
  visible = false,
}: {
  children: React.ReactNode;
  handleClose: () => void;
  title: string;
  visible?: boolean;
}) => {
  const { standalone } = useSavor();
  return visible ? (
    <>
      <DrawerOverlay onClick={handleClose} />
      <aside
        className={cn(
          "bg-primary bottom-0 fixed flex flex-col left-2 max-w-lg mx-auto overflow-x-auto right-2 rounded-t-2xl shadow-3xl text-background top-16 z-50",
          standalone && "pb-8"
        )}
      >
        <header className="border-background/5 border-b flex-none font-semibold py-4 text-center text-lg">
          {title}
        </header>
        {children}
      </aside>
    </>
  ) : null;
};

export const DrawerRow = ({
  id,
  label,
  checked,
  loading,
  handleClick,
}: {
  id: string;
  label: string;
  checked: boolean;
  loading: boolean;
  handleClick: () => void;
}) => (
  <div
    key={id}
    onClick={handleClick}
    className="border-background/5 border-t cursor-pointer filled flex gap-6 items-center px-6 py-3 text-lg"
  >
    <span className="bg-background/5 flex h-8 items-center justify-center rounded text-xl w-8">
      {loading ? <Loading label="" /> : checked && <Icon icon="check" />}
    </span>
    <span className="flex-auto">{label}</span>
  </div>
);

export const drawerIcon = "bg-background/5 flex h-8 items-center justify-center rounded text-2xl w-8";
