import { orderBy } from "lodash";

import { useAppNavigate } from "@/hooks/use-app-navigate";
import { useSavor } from "@/hooks/use-savor";
import { ImageWithFallback } from "../image-with-fallback";

export const SearchFilteredContent: React.FC = () => {
  const { filteredContent, onClickSearchResult } = useSavor();
  const navigate = useAppNavigate();
  const latestItems = orderBy(filteredContent, (si) => si.updatedAt, "desc");

  if (!filteredContent.length) {
    return null;
  }

  return (
    <>
      {latestItems.map((i) => (
        <div
          key={i.id}
          className="cursor-pointer flex items-center gap-4 px-4 text-lg truncate flex-shrink-0"
          onClick={(e) => {
            if (!i.entity) {
              return;
            }
            if (onClickSearchResult) {
              onClickSearchResult({ ...i.entity, entityId: i.entity.id });
            } else {
              navigate(`/entity/${i.entity.id}`);
              e.stopPropagation();
            }
          }}
        >
          <ImageWithFallback src={i.entity?.thumbnailUrl} className="h-8 flex-none object-cover rounded shadow w-8" />
          <span className="flex-auto truncate">{i.title}</span>
        </div>
      ))}
    </>
  );
};
