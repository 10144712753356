import { orderBy } from "lodash";
import { HTMLAttributes } from "react";

import { useSavor } from "@/hooks/use-savor";
import { cn } from "@/utils";

export const ImageHeader: React.FC<HTMLAttributes<HTMLElement> & { className?: string }> = ({
  children,
  className,
  ...props
}) => {
  const { savedItems } = useSavor();
  const mostRecent = orderBy(
    savedItems.filter((si) => Boolean(si.entity)),
    (s) => s.createdAt,
    "desc"
  )[0]?.entity!.imageUrl;

  return (
    <h1
      className={cn(
        "bg-center bg-cover bg-clip-text bg-foreground/25 font-[1000] invert-[0.2] text-transparent text-5xl tracking-widest uppercase",
        className
      )}
      style={{
        backgroundImage: `url("${
          mostRecent ?? "https://upload.wikimedia.org/wikipedia/en/c/c2/Back_to_the_Future_Part_II.jpg"
        }")`,
      }}
      {...props}
    >
      {children}
    </h1>
  );
};
