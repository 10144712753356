import { isAfter, subMinutes } from "date-fns";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { maxBy } from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import { FakeSearch, MegaButton } from "@/components/fake-search";
import { Footer } from "@/components/footer";
import { Loading } from "@/components/ui/loading";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useProfileUser } from "@/hooks/use-profile-user";
import { useSavor } from "@/hooks/use-savor";
import { useMyFollowedListsQuery } from "../../generated/graphql";
import { ListOfLists } from "../lists/list-of-lists";
import { Library } from "./library";

const myStuffTabAtom = atomWithStorage<string | undefined>("myStuffTab", "library");

export const MyStuff: React.FC = () => {
  const navigate = useNavigate();
  const { myLists } = useSavor();
  const { user, loading } = useProfileUser();
  const [tab, setTab] = useAtom(myStuffTabAtom);

  // When a new list is added, scroll to it
  // A little hacky, but &shrug;
  useEffect(() => {
    const recentlyCreated = maxBy(myLists, (l) => l.createdAt);
    if (recentlyCreated && isAfter(new Date(recentlyCreated.createdAt), subMinutes(new Date(), 1))) {
      document.getElementById(recentlyCreated.id)?.scrollIntoView();
    }
  }, [myLists.length]);

  const { data: { myFollowedLists } = { myFollowedLists: [] } } = useMyFollowedListsQuery({
    fetchPolicy: "cache-first",
  });

  if (!user) {
    return null;
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <header className="flex h-16 items-center justify-center px-4">
        <h1 className="text-xl">My Stuff</h1>
      </header>
      <Tabs defaultValue={tab}>
        <TabsList className="flex shadow sticky top-0 z-20">
          <TabsTrigger value="library" onClick={() => setTab("library")}>
            Library
          </TabsTrigger>
          <TabsTrigger value="lists" onClick={() => setTab("lists")}>
            Lists
          </TabsTrigger>
          <TabsTrigger value="following" onClick={() => setTab("following")}>
            Following
          </TabsTrigger>
        </TabsList>
        <TabsContent value="library">
          <Library />
          <FakeSearch />
        </TabsContent>
        <TabsContent value="lists">
          <ListOfLists lists={myLists} />
          <MegaButton
            label="Create New List"
            leftIcon="format_list_bulleted"
            rightIcon="arrow_forward"
            onClick={() => navigate("/create-list")}
          />
        </TabsContent>
        <TabsContent value="following">
          {myFollowedLists.length === 0 ? (
            <p className="text-gray-500 italic">You aren't following any lists yet, here are some suggestions:</p>
          ) : (
            <ListOfLists lists={myFollowedLists} />
          )}
        </TabsContent>
      </Tabs>
      <Footer header="Savor">
        <h2 className="mt-4">Build the Internet of You</h2>
        <p className="mt-8">Start with something you love, or a recommendation from a friend.</p>
      </Footer>
    </>
  );
};
