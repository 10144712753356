import { useIsLoggedIn } from "@/auth/user";
import { buttonClassNames, ThreeDotMenu } from "@/components/three-dot-menu";
import { Icon } from "@/components/ui/icon";
import { useAppBack } from "@/hooks/use-app-navigate";
import { useSavor } from "@/hooks/use-savor";
import { shortTitle } from "@/utils";
import {
  EntityFragment,
  MyListsDocument,
  MySavedItemsDocument,
  useUpdateSavedItemMutation,
} from "../../generated/graphql";

interface Props {
  entity: EntityFragment;
  mobile?: boolean;
}

export const EntityHeader: React.FC<Props> = ({ entity, mobile }) => {
  const loggedIn = useIsLoggedIn();
  const goBack = useAppBack();
  const { savedItems } = useSavor();
  const savedItem = savedItems.find((si) => si.entity?.id === entity.id);
  // TODO: Replace with a field on entity EntityFragment
  const isSaved = loggedIn && savedItem;
  const [updateSavedItem] = useUpdateSavedItemMutation();

  return (
    <>
      <div className="fixed bg-gradient-to-r from-transparent to-primary inset-0 mix-blend-hard-light opacity-25 pointer-events-none" />
      <div className="fixed bg-gradient-to-l from-transparent to-foreground inset-0 mix-blend-hard-light opacity-25 pointer-events-none" />
      <div className="fixed bg-gradient-to-b from-transparent to-background inset-0 pointer-events-none" />
      {!mobile && (
        <nav className="flex inset-0 items-center gap-4 h-16 justify-between px-4 fixed top-0 w-full z-10">
          <button className={buttonClassNames} onClick={goBack}>
            <Icon icon="arrow_back" />
          </button>
          {isSaved && (
            <ThreeDotMenu
              isPublic={savedItem.public}
              setPublic={(val) => updateSavedItem({ variables: { input: { itemId: savedItem.id, public: val } } })}
              deleteFn={() =>
                updateSavedItem({
                  variables: { input: { itemId: savedItem.id, shouldDelete: true } },
                  refetchQueries: [MySavedItemsDocument, MyListsDocument],
                  onCompleted: goBack,
                })
              }
            />
          )}
        </nav>
      )}
      <h1 className="font-sans-narrow font-[200] leading-[2.5rem] line-clamp-1 mx-14 my-[0.75rem] self-stretch text-[2.5rem] text-center tracking-[0] uppercase">
        {shortTitle(entity.title)}
      </h1>
    </>
  );
};
