import React, { useCallback, useContext, useRef, useState } from "react";

import { Dialog, DialogContent } from "./ui/dialog";

type RenderFn<T> = (close: (data?: T) => void) => React.ReactNode;
interface ModalContextData {
  open<T>(render: RenderFn<T>): Promise<T | undefined>;
}
const ModalContext = React.createContext<ModalContextData>({ open: () => Promise.resolve(undefined) });

const Modal: React.FC<{ onClose: () => void; isOpen: boolean; content?: React.ReactNode }> = ({
  onClose,
  content,
  isOpen,
}) => {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(shouldBeOpen) => {
        if (!shouldBeOpen) {
          onClose();
        }
      }}
    >
      <DialogContent className="max-h-[75dvh] overflow-y-auto">{content}</DialogContent>
    </Dialog>
  );
};

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [{ isOpen, content }, setData] = useState<{
    isOpen: boolean;
    content?: React.ReactNode;
  }>({
    isOpen: false,
  });

  const resolver = useRef<(v?: unknown) => void>();

  const close = useCallback(function <T>(data?: T) {
    resolver.current?.(data);
    setData((prev) => ({ ...prev, isOpen: false }));
  }, []);

  const open = useCallback(
    function <T>(fn: RenderFn<T>) {
      // Resolve any previous promises
      resolver.current?.();

      return new Promise<T | undefined>((resolve, reject) => {
        try {
          resolver.current = resolve as any;
          const content = fn(close);
          setData({ isOpen: true, content });
        } catch (error) {
          reject(error);
        }
      });
    },
    [close]
  );

  return (
    <ModalContext.Provider value={{ open }}>
      <Modal onClose={close} content={content} isOpen={isOpen} />
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const { open } = useContext(ModalContext);

  return {
    openModal: open,
  };
};
