import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Icon } from "./ui/icon";

export const buttonClassNames = "bg-border/25 flex flex-none h-8 items-center justify-center rounded-full text-lg w-8";
interface Props {
  isPublic?: boolean;
  setPublic?: (val: boolean) => void;
  following?: boolean;
  setFollowing?: (val: boolean) => void;
  deleteFn?: () => void;
}

export const ThreeDotMenu: React.FC<Props> = ({ isPublic, setPublic, following, setFollowing, deleteFn }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className={buttonClassNames}>
          <Icon icon="more_horiz" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {isPublic !== undefined && setPublic && (
          <>
            <DropdownMenuLabel>Visibility</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuRadioGroup
              value={isPublic ? "public" : "private"}
              onValueChange={(v) => setPublic(v === "public")}
            >
              <DropdownMenuRadioItem value="public">
                <span className="flex-auto">Public</span>
                <Icon icon="public" />
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem value="private">
                <span className="flex-auto">Private</span>
                <Icon icon="visibility_off" />
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </>
        )}
        {following !== undefined && setFollowing && (
          <>
            <DropdownMenuLabel>Following</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuRadioGroup
              value={following ? "following" : "notFollowing"}
              onValueChange={(v) => setFollowing(v === "following")}
            >
              <DropdownMenuRadioItem value="following">
                <span className="flex-auto">Follow</span>
                <Icon icon="public" />
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem value="notFollowing">
                <span className="flex-auto">Unfollow</span>
                <Icon icon="visibility_off" />
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </>
        )}
        {deleteFn && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={deleteFn}>
              <span className="flex-auto">Delete</span>
              <Icon icon="delete" />
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
